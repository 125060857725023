import React, { useEffect, useState } from 'react'
import './userApiKeysTable.scss'
import { BsClipboardCheckFill, BsClipboardFill } from 'react-icons/bs'
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";


export default function UserApiKeysTable({
    apiKeysList,
}) {

    const [revealApiKey, setRevealApiKey] = useState([]);

    const [isCopiedToClipboardArr, setIsCopiedToClipboardArr] = useState([])

    useEffect(() => {
        if (isCopiedToClipboardArr && isCopiedToClipboardArr.length > 0) {
            const timer = setTimeout(() => {
                const copy = [...isCopiedToClipboardArr]
                copy.pop()
                setIsCopiedToClipboardArr(copy);
            }, 1500);

            return () => clearTimeout(timer); // Cleanup the timer on unmount or when copied changes
        }
    }, [isCopiedToClipboardArr]);

    const handleRevealApiKeyChange = (id) => {
        //if id is not in revealApiKey array then add to it, if not substract from it
        if (!revealApiKey.includes(id)) {
            setRevealApiKey([...revealApiKey, id]);
        }
        else {
            setRevealApiKey(revealApiKey.filter(item => item !== id));
        }
    }

    const handleCopyToClipboard = (id, apiKey) => {
        navigator.clipboard.writeText(apiKey);
        if (!isCopiedToClipboardArr.includes(id)) {
            setIsCopiedToClipboardArr([...isCopiedToClipboardArr, id]);
        }
    }

    const maskPassword = (apiKey) => {
        const length = apiKey.length;
        const threshold = 5;
        if (length <= threshold) {
            return apiKey; // If password is 5 characters or less, return as is
        }
        const maskedPart = '*'.repeat(length - threshold);
        const visiblePart = apiKey.slice(-threshold);
        return maskedPart + visiblePart;
    }

    return (
        <div className='apik-container'>
            <div className='apik-table-headline'>
                <h2 style={{ color: 'white' }}>Api Keys</h2>
            </div>
            <div className='apik-table-container'>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Api Key</th>
                            <th>Fecha de Expiración</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeysList && apiKeysList.map(item => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td className='user-apik-table-monospaced'>{revealApiKey.includes(item.id) ? item.apiKey : maskPassword(item.apiKey)}</td>
                                    <td>{item.expirationDate ? item.expirationDate.split('T')[0] : 'Nunca'}</td>
                                    <td>
                                        <div
                                            className="user-apik-table-visible"
                                            onClick={() => handleRevealApiKeyChange(item.id)}
                                        >
                                            {revealApiKey.includes(item.id) ? <AiFillEye /> : <AiFillEyeInvisible />}
                                        </div>
                                        <div
                                            className={`user-apik-table-clipboard ${isCopiedToClipboardArr.includes(item.id) && 'user-apik-table-clipboard-copied'}`}
                                            onClick={() => handleCopyToClipboard(item.id, item.apiKey)}
                                        >
                                            {isCopiedToClipboardArr.includes(item.id) ? <BsClipboardCheckFill /> : <BsClipboardFill />}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        }
                        {apiKeysList && apiKeysList.length === 0 &&
                            <tr>
                                <td colSpan={4}>No existen Api Keys creadas para este usuario.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}
