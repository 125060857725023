import "./formSelect.scss";
import classnames from "classnames";

const FormSelect = ({
  id,
  label,
  labelPosition,
  defaultValue,
  options,
  onChange,
}) => {
  return (
    <div
      className={
        labelPosition === "upon"
          ? "form-select-control-upon"
          : "form-select-control"
      }
    >
      <div className="form-select-label-content">
        <label htmlFor={id} className="form-select-label">
          {label}
        </label>
      </div>

      <select
        name="select"
        className="form-select-field"
        value={defaultValue ? defaultValue : "select"}
        onChange={(event) => onChange(id, event.target.value)}
      >
        <option value="select" disabled>
          Seleccionar
        </option>
        {options.map((option) => (
          <option key={`${option.id}-option`} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
