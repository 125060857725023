import "./colorField.scss";
import classnames from "classnames";


const ColorField = ({ id, label, color}) => {
  return (
    <div id={id} className={
        classnames("color-field",{
            "color-field-green": color === "green",
            "color-field-blue": color === "blue",
            "color-field-red": color === "red",
            "color-field-yellow": color === "yellow",
            "color-field-orange": color === "orange",
            "color-field-black": color === "black",
            "color-field-grey": color === "grey",
        })}>
        {label}
    </div>
  );
};

export default ColorField;

