import { useState, useEffect } from "react";
import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_INTEGRALABS_SERVER}`,
  timeout: 180000,
});

const useConvertFromKML = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/convert/kml_to_geojson`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useConvertFromKMZ = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/convert/kmz_to_geojson`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useSustainabilityAnalisis = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data, params) => {
    setBody(data);
    setParams(params);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/extract/sustainability_analisis`, body, { params })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useCropIdentification = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/api/maps/soil_identification`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useSoilIdentification = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/extract/soil_identification`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useOtbnAnalisis = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/extract/otbn_analisis`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useExtractInfo = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (params) => {
    setParams(params);
    setRefetch(true);
  };

  useEffect(() => {
    if (params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/extract/info`, { params })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useReport = () => {
  const [res, setRes] = useState({ status: "", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/report/from_geojson_to_json_to_excel`, body, {
          responseType: "blob",
        })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useNDVIFromJSON = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/ndvi/`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

export default {
  useConvertFromKML,
  useConvertFromKMZ,
  useCropIdentification,
  useSoilIdentification,
  useSustainabilityAnalisis,
  useOtbnAnalisis,
  useNDVIFromJSON,
  useExtractInfo,
  useReport,
};
