import "./miniMenu.scss";
import React, { Fragment } from "react";
import classnames from "classnames";

const MiniMenu = ({ children, itemList, section, setSection }) => {
  return (
    <Fragment>
      <div className="mini-menu">
        {itemList.map((item) => (
          <Fragment key={item.id}>
            {item.dependsOn === undefined || item.dependsOn ? (
              <div
                className={classnames("mini-menu-item", {
                  "mini-menu-item-selected": section === item.id,
                })}
                onClick={() => setSection(item.id)}
              >
                <div
                  className={classnames("mini-menu-icon", {
                    "mini-menu-icon-selected": section === item.id,
                  })}
                >
                  {item.icon}
                </div>
              </div>
            ) : null}
          </Fragment>
        ))}
      </div>
      <div className="mini-menu-content">{children}</div>
    </Fragment>
  );
};

export default MiniMenu;
