import "./menu.scss";
import React, { Fragment } from "react";

const Menu = ({ children, content }) => {
  return (
    <Fragment>
      <div className="menu">{children}</div>
      <div className="menu-content">{content}</div>
    </Fragment>
  );
};

export default Menu;
