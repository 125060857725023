import "./formMessage.scss";
import classNames from "classnames";

const FormMessage = ({ id, label, size, criticality }) => {
  return (
    <small key={id} className={
        classNames("form-message",
        {
        "form-message-small": size === "small",
        "form-message-medium": size === "medium",
        "form-message-big": size === "big",
        "form-message-success": criticality === "success",
        "form-message-error": criticality === "error",
        "form-message-warning": criticality === "warning"
        })
    }>
    {label}
    </small>
  );
};

export default FormMessage;
