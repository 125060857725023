import "./formPassword.scss";
import classnames from "classnames";
import { useState } from "react";

import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai"

const FormPassword = ({ id, label, labelPosition, placeholder, value, shownable, required, onChange }) => {
  const [show, setShow] = useState(false)
  
  return (
    <div className="form-password-control">
      <label htmlFor={id} className="form-password-label">
        {label}
      </label>
      <div className={classnames("form-password-field", { "form-password-field-on": labelPosition === "upon" })}>
        <input className="form-password-input"
          id={id}
          type={show?"text":"password"}
          placeholder={placeholder}
          value={value ? value : ""}
          onChange={(event) => onChange(id, event.target.value)}
          required={required}
        />
        {shownable?
        <div className="form-password-icon" onClick={() => setShow(!show)}>
          {show ? <AiFillEye/>: <AiFillEyeInvisible/>}
        </div> : null}
      </div>


    </div>
  );
};

export default FormPassword;
