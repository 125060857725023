import "./loader.scss";
import React from "react";
import classnames from "classnames";

const Loader = ({ size }) => {
  return (
    <div className="loader-content">
      <div className="loader-spin-content">
        <div
          className={classnames({
            "loader-spin-big": size === "big",
            "loader-spin-medium": size === "medium",
            "loader-spin-small": size === "small",
            "loader-hidden": size === undefined,
          })}
        />
      </div>

      <div
        className={classnames({
          "loader-text-big": size === "big",
          "loader-text-medium": size === "medium",
          "loader-text-small": size === "small",
          "loader-hidden": size === undefined,
        })}
      >
        Cargando...
      </div>
    </div>
  );
};

export default Loader;
