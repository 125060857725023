import "./analitics.scss";
import { Fragment, useEffect, useState } from "react";
import classnames from "classnames";

import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

import { Grid, GridRow } from "../../common/Grid/grid";
import Form from "../../common/Form/form";

import analiticsModel from "../../models/analiticsModel";

import {
  graphConfig,
  getActualMonth,
  createListYear,
  createListMonth,
} from "./utils";
import Loader from "../../common/Loader/loader";

const UserManager = ({}) => {
  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [farmList, setFarmList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [date, setDate] = useState(getActualMonth());

  const getAnalitics = analiticsModel.useGetAnalitics(); // Hook para consultar analitics

  // Consulto las analitics
  useEffect(() => {
    getAnalitics.fetch();
  }, []);

  // Recibo la respueta de la consulta de analitics
  useEffect(() => {
    if (getAnalitics.res.status === "load") {
      setUserList(getAnalitics.res.data.userList);
      setClientList(getAnalitics.res.data.clientList);
      setFarmList(getAnalitics.res.data.farmList);
      setData({ user: getAnalitics.res.data.userList[0].id, client: "all" });
      setLoading(false);
    }
  }, [getAnalitics.res.status]);

  const UserConfig = [
    {
      id: "user",
      label: "Usuario",
      type: "select",
      placeholder: "",
      labelPosition: "left",
      width: 3,
      options: [
        ...userList.map((item) => {
          return {
            id: item.id,
            label: `${item.name} ${item.lastname}`,
            value: item.id,
          };
        }),
      ],
    },
    {
      id: "client",
      label: "Productor",
      type: "select",
      placeholder: "",
      defaultValue: "",
      labelPosition: "left",
      width: 3,
      options: [
        { id: "all", label: "All", value: "all" },
        ...clientList
          .filter((item) => item.userId == data.user)
          .map((item) => {
            return {
              id: item.id,
              label: `${item.name}`,
              value: item.id,
            };
          }),
      ],
    },
  ];

  const DateConfig = [
    {
      id: "year",
      label: "Año",
      type: "select",
      placeholder: "",
      labelPosition: "left",
      width: 3,
      options: [{ id: "all", label: "All", value: "all" }, ...createListYear()],
    },
    {
      id: "month",
      label: "Mes",
      type: "select",
      placeholder: "",
      defaultValue: "",
      labelPosition: "left",
      width: 3,
      options: [
        { id: "all", label: "All", value: "all" },
        ...createListMonth(),
      ],
    },
  ];

  useEffect(() => {
    setData({ ...data, client: "all" });
  }, [data.user]);

  Chart.register(...registerables);

  return (
    <div className="analitics-content">
      <Grid>
        <GridRow key={11} width={2}>
          <div
            className={classnames("analitics-stat-panel", "analitics-yellow")}
          >
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Fragment>
                <div className="analitics-stat-title">Usuarios</div>
                <div className="analitics-stat-value">{userList.length}</div>
              </Fragment>
            )}
          </div>
        </GridRow>

        <GridRow key={12} width={2}>
          <div className={classnames("analitics-stat-panel", "analitics-blue")}>
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Fragment>
                <div className="analitics-stat-title">Productores</div>
                <div className="analitics-stat-value">{clientList.length}</div>
              </Fragment>
            )}
          </div>
        </GridRow>

        <GridRow key={13} width={2}>
          <div
            className={classnames("analitics-stat-panel", "analitics-green")}
          >
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Fragment>
                <div className="analitics-stat-title">Lotes</div>
                <div className="analitics-stat-value">{farmList.length}</div>
              </Fragment>
            )}
          </div>
        </GridRow>
        <GridRow key={1} width={6} />
      </Grid>

      <Form
        list={UserConfig}
        data={data}
        setData={setData}
        submit={null}
        cancel={null}
      />
      <Form
        list={DateConfig}
        data={date}
        setData={setDate}
        submit={null}
        cancel={null}
      />
      <Grid>
        <GridRow key={21} width={2} />
        <GridRow key={22} width={5}>
          <div
            className={classnames(
              "analitics-stat-panel-slim",
              "analitics-blue"
            )}
          >
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Fragment>
                <div className="analitics-stat-text">Productores</div>
                <div className="analitics-stat-text">
                  {
                    clientList
                      .filter((item) => item.userId == data.user)
                      .filter((item) =>
                        date.year === "all"
                          ? item
                          : item.created.includes(date.year)
                      )
                      .filter((item) =>
                        date.month === "all"
                          ? item
                          : item.created.includes(date.month)
                      ).length
                  }
                </div>
              </Fragment>
            )}
          </div>
          <div className="analitics-panel-content">
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Line
                datasetIdKey="id"
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Productores",
                      font: {
                        size: "18px",
                        family: ["Muli", "sans-serif"],
                      },
                      color: "black",
                    },
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={graphConfig(
                  clientList.filter((item) => item.userId == data.user),
                  "blue"
                )}
              />
            )}
          </div>
        </GridRow>
        <GridRow key={23} width={5}>
          <div
            className={classnames(
              "analitics-stat-panel-slim",
              "analitics-green"
            )}
          >
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Fragment>
                <div className="analitics-stat-text">Lotes</div>
                <div className="analitics-stat-text">
                  {
                    farmList
                      .filter((item) => item.userId == data.user)
                      .filter((item) =>
                        data.client === "all"
                          ? item
                          : item.clientId == data.client
                      )
                      .filter((item) =>
                        date.year === "all"
                          ? item
                          : item.created.includes(date.year)
                      )
                      .filter((item) =>
                        date.month === "all"
                          ? item
                          : item.created.includes(date.month)
                      ).length
                  }
                </div>
              </Fragment>
            )}
          </div>
          <div className="analitics-panel-content">
            {loading ? (
              <Loader size="medium" />
            ) : (
              <Line
                datasetIdKey="id"
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Lotes",
                      font: {
                        size: "18px",
                        family: ["Muli", "sans-serif"],
                      },
                      color: "black",
                    },
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={graphConfig(
                  farmList
                    .filter((item) => item.userId == data.user)
                    .filter((item) =>
                      data.client === "all"
                        ? item
                        : item.clientId == data.client
                    ),
                  "green"
                )}
              />
            )}
          </div>
        </GridRow>
      </Grid>
    </div>
  );
};

export default UserManager;
