import "./submenu.scss";
import { useState } from "react";

import { AiOutlineRightCircle } from "react-icons/ai";

const Submenu = ({ hideMenu, setHideMenu, title, children }) => {
  // const [hideMenu, setHideMenu] = useState(hide === true);

  return (
    <div className={hideMenu ? "submenu-content-hidden" : "submenu-content"}>
      <div className="submenu-title-content">
        <button
          className={
            hideMenu ? "submenu-deploy-icon" : "submenu-deploy-icon-rotate"
          }
          onClick={() => setHideMenu(!hideMenu)}
        >
          <AiOutlineRightCircle />
        </button>
        <div className="submenu-title">
          <div className="submenu-title-box">{title}</div>
        </div>
      </div>

      <div className={hideMenu ? "submenu-body-hidden" : "submenu-body"}>
        {children}
      </div>
    </div>
  );
};

export default Submenu;
