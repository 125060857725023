import React, { Fragment } from 'react'
import './apiKeysTable.scss'
import { BsPencilFill, BsPlusSquareFill, BsTrash } from 'react-icons/bs'
import FormButton from '../../common/FormButton/formButton'
import { Grid, GridRow } from '../../common/Grid/grid'

export default function ApiKeysTable({
    apiKeysList,
    deleteApiKey,
    editApiKey,
    addApiKey
}) {
    return (
        <div className='apik-container'>
            <div className='apik-table-headline'>
                <h2>Api Keys</h2>
                <Grid style={{ width: 110 }}>
                    <GridRow width={12}>
                        <FormButton
                            id={'add-api-key'}
                            label="Agregar"
                            color="green"
                            type="button"
                            onClick={() => addApiKey(true)}
                            disabled={false}
                        />
                    </GridRow>
                </Grid>

            </div>
            <div className='apik-table-container'>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Api Key</th>
                            <th>Fecha de Expiración</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeysList && apiKeysList.map(item => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.apiKey}</td>
                                    <td>{item.expirationDate ? item.expirationDate.split('T')[0] : 'Nunca'}</td>
                                    <td>
                                        <div
                                            className="user-manager-edit"
                                            onClick={() => {
                                                const newItem = { ...item, expirationDate: item.expirationDate ? item.expirationDate.split('T')[0] : null }
                                                editApiKey(newItem)
                                            }}
                                        >
                                            <BsPencilFill />
                                        </div>

                                        <div
                                            className="user-manager-delete"
                                            onClick={() => deleteApiKey(item.id)}
                                        >
                                            <BsTrash />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        }
                        {apiKeysList && apiKeysList.length === 0 &&
                            <tr>
                                <td colSpan={4}>No existen Api Keys creadas para este usuario.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}
