export default [
    {
        id: "id",
        label: "Id",
        width: 6
    },
    {
        id: "name",
        label: "Nombre",
        width: 6
    },
    {
        id: "city",
        label: "Localidad",
        width: 6
    },
    {
        id: "province",
        label: "Provincia",
        width: 6
    },
    {
        id: "latitude",
        label: "Latitud",
        width: 6
    },
    {
        id: "longitude",
        label: "Longitud",
        width: 6
    },
    {
        id: "area",
        label: "Superficie Total",
        width: 6,
        render: (column) => {
            return column ? column + ' ha' : "0.00 ha";
        }
    },
    {
        id: "campaignId",
        label: "Campaña",
        width: 6,
        render: (column) => {
            return column ? column : "23/24";
        }
    },
    {
        id: "last_analyzed_at",
        label: "Último Análisis",
        width: 6,
        render: (column) => {
            return column ? column.split("T")[0] : "";
        }
    }
];