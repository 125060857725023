import "./mapExample.scss";
import React, { Fragment, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import {
  LayersControl,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  MapContainer,
  useMap,
  useMapEvents,
  Marker,
  Tooltip,
  SVGOverlay,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import bbox from "geojson-bbox";
import * as turf from "@turf/turf";

const MapExample = ({
  center,
  zoom,
  drawable,
  clear,
  polygon,
  setPolygon,
  fixedPolygon,
  showCoordinates,
  layerPolygon,
  hideControl,
}) => {
  const DrawingTool = ({ drawable, clear, polygon, setPolygon }) => {
    const map = useMap();
    const editLayerRef = useRef();
    let drawControlRef = useRef();

    useEffect(() => {
      L.drawLocal.draw.toolbar.buttons.polygon = "Dibujar Lote";

      L.drawLocal.draw.toolbar.actions.title = "Cancelar Lote";
      L.drawLocal.draw.toolbar.actions.text = "Cancelar";

      L.drawLocal.draw.toolbar.finish.title = "Finalizar";
      L.drawLocal.draw.toolbar.finish.text = "Finalizar";

      L.drawLocal.draw.toolbar.undo.title = "Deshacer";
      L.drawLocal.draw.toolbar.undo.text = "Deshacer";

      L.drawLocal.draw.handlers.polygon.tooltip.start =
        "Seleccione el punto de inicio del lote";
      L.drawLocal.draw.handlers.polygon.tooltip.cont =
        "Seleccione los vertices del lote";
      L.drawLocal.draw.handlers.polygon.tooltip.end =
        "Al finalizar cierre el lote";

      L.drawLocal.edit.toolbar.buttons.edit = "Editar Lote";
      L.drawLocal.edit.toolbar.buttons.editDisabled =
        "No existen lotes para editar";

      L.drawLocal.edit.toolbar.actions.save.title = "Guardar cambios";
      L.drawLocal.edit.toolbar.actions.save.text = "Guardar";

      L.drawLocal.edit.toolbar.actions.cancel.title = "Cancelar";
      L.drawLocal.edit.toolbar.actions.cancel.text = "Cancelar";

      L.drawLocal.edit.toolbar.actions.clearAll.title = "Eliminar todo";
      L.drawLocal.edit.toolbar.actions.clearAll.text = "Eliminar todo";

      L.drawLocal.edit.handlers.edit.tooltip.subtext =
        "Presione cancelar para deshacer los cambios";
      L.drawLocal.edit.handlers.edit.tooltip.text =
        "Presione sobre un vertice para eliminarlo o arrastrelo para editarlo";

      L.drawLocal.edit.toolbar.buttons.remove = "Eliminar Lote";
      L.drawLocal.edit.toolbar.buttons.removeDisabled =
        "No existen lotes para eliminar";

      L.drawLocal.edit.handlers.remove.tooltip.text =
        "Presione sobre un lote para eliminarlo o presione cancelar para deshacer los cambios ";
    }, []);

    useEffect(() => {
      if (drawable) {
        map.addControl(drawControlRef.current);
      } else {
        map.removeControl(drawControlRef.current);
      }
    }, [drawable, map]);

    useEffect(() => {
      editLayerRef.current.clearLayers();
    }, [clear, map]);

    function onMounted(ctl) {
      drawControlRef.current = ctl;
    }

    useEffect(() => {
      if (polygon) {
        let dataLayer = new L.GeoJSON(polygon);
        dataLayer.eachLayer((layer) => {
          editLayerRef.current.addLayer(layer);
        });
      }
    }, [polygon]);

    return (
      <FeatureGroup ref={editLayerRef}>
        <EditControl
          position="topright"
          onCreated={(e) => {
            const geojson = e.layer.toGeoJSON();
            geojson.properties.id = uuid();
            setPolygon(geojson);
          }}
          onEdited={(e) => {
            e.layers.eachLayer((a) => {
              setPolygon(a.toGeoJSON());
            });
          }}
          onDeleted={(e) => {
            setPolygon(null);
          }}
          onMounted={onMounted}
          draw={{
            polygon: polygon ? false : true,
            polyline: false,
            circle: false,
            rectangle: false,
            marker: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
    );
  };

  function Bounds({ polygon }) {
    const map = useMap();

    useEffect(() => {
      if (!map) return;
      if (!polygon) return;

      const bounds = bbox({
        type: "FeatureCollection",
        features: polygon,
      });

      if (
        bounds[0] !== Infinity &&
        bounds[1] !== Infinity &&
        bounds[2] !== Infinity &&
        bounds[3] !== Infinity
      ) {
        map.fitBounds([
          [bounds[3], bounds[2]],
          [bounds[1], bounds[0]],
        ]);
      }
    }, [map, polygon]);

    return null;
  }

  return (
    <Fragment>
      <MapContainer center={center} zoom={zoom} scrollWheelZoom={true}>
        {hideControl ? (
          <TileLayer
            attribution="google"
            url="https://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
          />
        ) : (
          <LayersControl>
            <LayersControl.BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="SatelliteView">
              <TileLayer
                attribution="google"
                url="https://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
        )}
        <DrawingTool
          drawable={drawable}
          clear={clear}
          polygon={polygon}
          setPolygon={setPolygon}
        />
        {layerPolygon.length
          ? layerPolygon.map((item) => (
              <GeoJSON
                key={item.properties.id}
                data={item}
                color={item.properties.color}
                fillOpacity={0.4}
                weight={0}
              />
            ))
          : null}
        {fixedPolygon.map((item) => (
          <Fragment>
            <GeoJSON
              key={item.properties.id}
              data={item}
              color={item.properties.color ? item.properties.color : "#ABB2B9"}
              fillOpacity={0.4}
            />
            {showCoordinates ? (
              <Fragment>
                <SVGOverlay
                  bounds={[
                    [
                      turf.centroid(item.geometry).geometry.coordinates[1] +
                        0.0005,
                      turf.centroid(item.geometry).geometry.coordinates[0] -
                        0.0005,
                    ],
                    [
                      turf.centroid(item.geometry).geometry.coordinates[1] -
                        0.0005,
                      turf.centroid(item.geometry).geometry.coordinates[0] +
                        0.0005,
                    ],
                  ]}
                >
                  <circle r="5" cx="50%" cy="50%" fill="black" />
                  <circle r="3" cx="50%" cy="50%" fill="white" />
                  <circle r="1" cx="50%" cy="50%" fill="black" />
                </SVGOverlay>

                <SVGOverlay
                  bounds={[
                    [
                      turf.centroid(item.geometry).geometry.coordinates[1],
                      turf.centroid(item.geometry).geometry.coordinates[0],
                    ],
                    [
                      turf.centroid(item.geometry).geometry.coordinates[1] -
                        0.005,
                      turf.centroid(item.geometry).geometry.coordinates[0] +
                        0.01,
                    ],
                  ]}
                >
                  <text x="0px" y="14px" font-size="12px" stroke="black">
                    {turf.centroid(item.geometry).geometry.coordinates[1]}
                  </text>
                  <text x="0px" y="28px" font-size="12px" stroke="black">
                    {turf.centroid(item.geometry).geometry.coordinates[0]}
                  </text>
                </SVGOverlay>

                {item.geometry.coordinates.map((polygon) =>
                  polygon.map((coordinate) => (
                    <Fragment>
                      <SVGOverlay
                        bounds={[
                          [coordinate[1] + 0.0005, coordinate[0] - 0.0005],
                          [coordinate[1] - 0.0005, coordinate[0] + 0.0005],
                        ]}
                      >
                        <circle r="5" cx="50%" cy="50%" fill="black" />
                        <circle r="3" cx="50%" cy="50%" fill="white" />
                        <circle r="1" cx="50%" cy="50%" fill="black" />
                      </SVGOverlay>
                      <SVGOverlay
                        bounds={[
                          [coordinate[1], coordinate[0]],
                          [coordinate[1] - 0.005, coordinate[0] + 0.01],
                        ]}
                      >
                        <text x="0px" y="14px" font-size="12px" stroke="black">
                          {coordinate[1]}
                        </text>
                        <text x="0px" y="28px" font-size="12px" stroke="black">
                          {coordinate[0]}
                        </text>
                      </SVGOverlay>
                    </Fragment>
                  ))
                )}
              </Fragment>
            ) : null}
          </Fragment>
        ))}

        {fixedPolygon ? <Bounds polygon={fixedPolygon} /> : null}
        {polygon ? <Bounds polygon={[polygon]} /> : null}
      </MapContainer>
    </Fragment>
  );
};

export default MapExample;
