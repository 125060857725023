import "./formMultiSelect.scss";
import {useState, useEffect, useRef, Fragment} from 'react'

import {BiChevronDown} from 'react-icons/bi'
import {ImCross} from 'react-icons/im'

const FormMultiSelect = ({ id, label, labelPosition, defaultValue, options, onChange }) => {
  // const options = ["Admin", "Remote Sensing"]

  const [showDropdown, setShowDropdown] = useState(false)
  const [optionList, setOptionList] = useState([])
  const [listedOptions, setListedOptions] = useState([])
  const [selectedOptionList, setSelectedOptionList] = useState([])

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (defaultValue && defaultValue.length !== 0){
      setOptionList(options.filter(item => !defaultValue.includes(item.id)))
      setListedOptions(options.filter(item => !defaultValue.includes(item.id)))
      setSelectedOptionList(options.filter(item => defaultValue.includes(item.id)))
    } else {
      setOptionList(options)
      setListedOptions(options)
      setSelectedOptionList([])
    }
  }, [])

  useEffect(() => {
    if (onChange) onChange(id, selectedOptionList.map(item => item.id))
  }, [selectedOptionList])

  useEffect(() => {
    setListedOptions(optionList.sort(((a, b) => a.id - b.id)))
  }, [optionList])

  useEffect(() => {
    if (listedOptions.length === 0) setShowDropdown(false)
    else setShowDropdown(showDropdown)
  }, [listedOptions])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
        document.getElementById(`${id}-search`).value = ""
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleDropdown = () => {
    if (listedOptions.length === 0) setShowDropdown(false)
    else setShowDropdown(!showDropdown);
  };

  const onInput = (event) => {
    const input = event.target.value
    if (input !== "") {
      setListedOptions(optionList.filter(item => item.label.toLowerCase().includes(event.target.value.toLowerCase())))
    } else {
      setListedOptions(optionList)
    }
  }

  const onClick = () => {
    toggleDropdown()
    setListedOptions(optionList)
  }

  const addOption = (option) => {
    document.getElementById(`${id}-search`).value = ""
    setOptionList(optionList.filter(item => item.id !== option.id))
    setSelectedOptionList([...selectedOptionList, option])
  }

  const removeOption = (option) => {
    setSelectedOptionList(selectedOptionList.filter(item => item.id !== option.id))
    setOptionList([...optionList, option])
  }

  return (
    <div className={labelPosition === "upon"?"multi-select-control-upon":"multi-select-control"}>
      <div className="multi-select-label-content">
      <label htmlFor={id} className="multi-select-label">
        {label}
       </label>
      </div>

      <div className="multi-select-ref" ref={wrapperRef}>
        <div id={id} className="multi-select-field" onClick={() => onClick()}>
          <div className="multi-select-field-body-content">
            <div className="multi-select-field-body">
              {selectedOptionList.map(option => (
                <div
                  key={option.id}
                  className="multi-select-option-selected"
                  onClick={() => removeOption(option)}
                >
                    <div className="multi-select-option-selected-text">
                      {option.label}
                    </div>
                    <div className="multi-select-option-selected-icon">
                      <ImCross/>
                    </div>
                </div>
              ))}
              <input
                type="text"
                id={`${id}-search`}
                placeholder={selectedOptionList.length !== 0?"":"Seleccionar"}
                autoComplete="off"
                onChange={(event) => onInput(event)}
                className="multi-select-input"
              />
            </div>
          </div>

          <div
            onClick={toggleDropdown}
            className="multi-select-dropdown-icon"
          >
            <BiChevronDown/>
          </div>
        
        </div>
        {showDropdown?
          <div className="multi-select-dropdown">
            {listedOptions.map(option => (
              <div
                key={option.id}
                className="multi-select-option"
                onClick={() => addOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>:
        null}
      </div>
    </div>
    
  )
};

export default FormMultiSelect;
