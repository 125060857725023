import "./linkContainer.scss";
import { Link } from "react-router-dom";

const LinkContainer = ({ message, path }) => {
  return (
    <div className="link-container-content">
      <div className="link-message">
        {message}
      </div>
      <div className="link-message">
        <Link to={path} className="link-container-link">
          AQUÍ
        </Link>
      </div>
    </div>
  );
};

export default LinkContainer;
