import "./farm.scss";
import { useState, useEffect, Fragment } from "react";

import Info from "../../../common/Info/info";
import Submenu from "../../../common/Submenu/submenu";
import MenuCheck from "../../../common/MenuCheck/menuCheck";

import Loader from "../../../common/Loader/loader";
import Error from "../../../common/Error/error";
import Form from "../../../common/Form/form";

import farmModel from "../../../models/farmModel";
import integralabsModel from "../../../models/integralabsModel";

import { BackButton, DownloadButton } from "../../../custom/Buttons/buttons";

import fileDownload from "js-file-download";

import cropLayerConfig from "./configs/crop_layers.json";
import soilLayerConfig from "./configs/soil_layers.json";
import sustainabilityLayerConfig from "./configs/sustainability_layers.json";
import otbnLayerConfig from "./configs/otbn_layers.json";
import info from "./configs/info";
import form from "./configs/form.json";

import { processLayer } from "./utils";
import reportModel from "../../../models/reportModel";
import GeneralInfo from "../../../common/GeneralInfo/generalInfo";

const Farm = ({ setMenu, farmId, polygon, setPolygon, setLayer, setModalMessage }) => {
  const [farm, setFarm] = useState(null); // Guarda el estado del farm
  const [layerList, setLayerList] = useState([]); // Guarda todas las capas de un año
  const [filterLayerList, setFilterLayerList] = useState([]); // Guarda las capas seleccionadas
  const [formList, setFormList] = useState(form); // Guarda la configuarion de los campos del form (select del año)
  const [checkList, setCheckList] = useState(sustainabilityLayerConfig); // Guarda la lista de capas posibles
  const [checkData, setCheckData] = useState({ norm: "EPA" }); // Guarda el estado del select del año
  const [processedLayers, setProcessedLayers] = useState(false); // Guarda el estado de capas procesadas
  const [layerYear, setLayerYear] = useState(null); // Guarda el año de la banda procesada

  const [infoMenu, setInfoMenu] = useState(false);
  const [relevantInfoMenu, setRelevantInfoMenu] = useState(false);
  const [cropIdentificationMenu, setCropIdentificationMenu] = useState(true);
  const [soilIdentificationMenu, setSoilIdentificationMenu] = useState(true);
  const [sustainabilityMenu, setSustainabilityMenu] = useState(false);
  const [otbnMenu, setOtbnMenu] = useState(true);

  const [pollingInterval, setPollingInterval] = useState(null);

  const [loading, setLoading] = useState({
    layer: false,
    lastLayer: false,
    info: false,
  });
  const [error, setError] = useState({
    layer: false,
    lastLayer: false,
    info: false,
  });

  const getFarm = farmModel.useGet(); // Hook para consultar el farm
  const getCropLayers = integralabsModel.useCropIdentification(); // Hook para consultar todas las capas
  const getSoilLayers = integralabsModel.useSoilIdentification(); // Hook para consultar todas las capas
  const getSustainabilityLayers = integralabsModel.useSustainabilityAnalisis(); // Hook para consultar todas las capas
  const getPDFReport = reportModel.usePDFReport(); // Hook para consultar todas las capas

  // Consulto el farm
  // useEffect(() => {
  //   getFarm.fetch(farmId);
  // }, []);

  useEffect(() => {
    const fetchData = () => {
      console.log("polling farm")
      getFarm.fetch(farmId); 
    };

    fetchData(); // Initial fetch

    // Start polling every x seconds
    const seconds = 5;
    const intervalId = setInterval(fetchData, seconds * 1000);
    setPollingInterval(intervalId);

    return () => {
      // Clear interval on component unmount
      clearInterval(intervalId);
    };
  }, [farmId]);

  // Recibo la respueta de la consulta del farm
  useEffect(() => {
    if (getFarm.res.status === "load") { 
      //actualizo solo la primera vez el estado
      setFarm(prevState => !prevState ? getFarm.res.data : prevState);
      //si existe analisis
      if (getFarm.res.data.last_analyzed_at !== null) {
        setFarm(getFarm.res.data)
        clearInterval(pollingInterval)
      }
    }
  }, [getFarm.res.status, pollingInterval]);

  // Consulta analisis de sustentabilidad
  useEffect(() => {
    if (!sustainabilityMenu) {
      console.log(checkData);
    }
  }, [polygon, checkData, sustainabilityMenu]);

  // Al actualizarse el farm actulizao el poligono
  useEffect(() => {
    if (farm && farm.geoPolynomial) {
      const polygon = JSON.parse(farm.geoPolynomial);
      const newPolygon = {
        ...polygon,
        properties: {
          ...polygon.properties,
          id: farm.id,
          name: farm.name,
          clientId: farm.clientId,
          province: farm.province,
          city: farm.city,
          department: farm.department,
          postalCode: farm.postalCode,
          createdAt: farm.createdAt,
          updatedAt: farm.updatedAt,
        },
      };
      setPolygon([newPolygon]);
    }
  }, [farm]);

  // Funcion para descarga del excel
  const downloadPDFFile = () => {
    const body = polygon[0];
    body.properties = {};
    body.properties.id = farm.id ? farm.id : "";
    body.properties.clientId = farm.clientId ? farm.clientId : "";
    body.properties.name = farm.name ? farm.name : "";
    body.properties.province = farm.province ? farm.province : "";
    body.properties.city = farm.city ? farm.city : "";
    body.properties.department = farm.department ? farm.department : "";
    body.properties.postalCode = farm.postalCode ? farm.postalCode : "";
    body.properties.campaign = farm.campaignId ? farm.campaignId : "";
    body.properties.latitude = farm.latitude ? farm.latitude : "";
    body.properties.longitude = farm.longitude ? farm.longitude : "";
    body.properties.area = farm.area ? farm.area : "";
    body.properties.last_analyzed_at = farm.last_analyzed_at
      ? farm.last_analyzed_at
      : "";

    // Initialize the fetch queue
    getPDFReport.addToQueue(body, { norm: checkData["norm"], lang: 'es' });
    getPDFReport.addToQueue(body, { norm: checkData["norm"], lang: 'en' });
  };

  // Recibo la respuesta de descarga del PDF
  useEffect(() => {
    if (getPDFReport.res.status === "load") {
      const id = farm.id;
      const norm = getPDFReport.res.data.norm;
      const lang = getPDFReport.res.data.lang;
      const curtm = Date.now();
      const now = new Date(curtm);
      const day = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();
      fileDownload(
        getPDFReport.res.data.report,
        `report-${norm}-id${id}-${year}${month}${day}-${lang}.pdf`
      );

      // Process the next item in the queue
      getPDFReport.processNextInQueue();
    }
    if (getPDFReport.res.status === "error") {
      console.error(getPDFReport.res.msg);
      setModalMessage({
        type: "error",
        message: `Error al crear el reporte PDF.`,
      });

      // Process the next item in the queue even on error
      getPDFReport.processNextInQueue();

    }
  }, [getPDFReport.res.status]);

  // Funcion para volver a la pantalla anterior
  const back = () => {
    setPolygon([]);
    setLayer([]);
    setMenu("client");
  };

  return (
    <div className="farm-content">
      {farm === null || getFarm.res.status === "laoding" ? (
        <div>
          <Loader size="big" />
        </div>
      ) : (
        <Fragment>
          <div className="farm-title">{farm.name}</div>
          <Submenu hideMenu={infoMenu} setHideMenu={setInfoMenu} title={"Info"}>
            <GeneralInfo infoList={info} data={farm} />
          </Submenu>
          <Submenu
            hideMenu={sustainabilityMenu}
            setHideMenu={setSustainabilityMenu}
            title={"Generacion de Informe"}
          >
            <Fragment>
              <Form list={formList} data={checkData} setData={setCheckData} />
              <div className="farm-button">
                <DownloadButton
                  downloadPDF={downloadPDFFile}
                  loadingPDF={getPDFReport.res.status === "loading"}
                  analingPDF={farm.last_analyzed_at === null}
                />
              </div>
            </Fragment>
          </Submenu>
        </Fragment>
      )}

      <div className="farm-button">
        <BackButton goBack={back} />
      </div>
    </div>
  );
};

export default Farm;
