const getLastYear = () => {
  const monthList = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const array = [...Array(12).keys()].map((item) =>
    month - item >= 0
      ? `${year}-${monthList[month - item]}`
      : `${year - 1}-${monthList[12 + month - item]}`
  );
  return array.reverse();
};

export const getActualMonth = () => {
  const monthList = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return { year, month: monthList[month] };
};

export const graphConfig = (list, color) => {
  const monthList = getLastYear();
  const data = monthList.map(
    (month) => list.filter((client) => client.created == month).length
  );

  return {
    labels: monthList,
    datasets: [
      {
        id: 1,
        label: "",
        data: data,
        tension: 0.2,
        borderColor: color,
      },
    ],
  };
};

export const createListYear = () => {
  return [...Array(11).keys()].map((item) => {
    return {
      id: `${2020 + item}`,
      label: `${2020 + item}`,
      value: `${2020 + item}`,
    };
  });
};

export const createListMonth = () => {
  return [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ].map((item) => {
    return {
      id: (item.charAt(0).toUpperCase() + item.slice(1)).substring(0, 3),
      label: item.charAt(0).toUpperCase() + item.slice(1),
      value: (item.charAt(0).toUpperCase() + item.slice(1)).substring(0, 3),
    };
  });
};
