import "./generalInfo.scss";

const GeneralInfo = ({ infoList, data }) => {
    return (
        <table className="genInfo-table">
            <thead>
                
            </thead>
            <tbody>
                {infoList.filter(item => data[item.id]).map((item) => (
                    <tr key={item.id}>
                        <td>{item.label}:</td>
                        <td>{item.render ? item.render(data[item.id]) : data[item.id]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default GeneralInfo