import "./info.scss";
import { Grid, GridRow } from "../Grid/grid";

const Info = ({ infoList, data }) => {
  return (
    <Grid>
      {infoList.map((item) => (
        <GridRow key={item.id} width={item.width}>
          <div className="info-group">
            <label htmlFor={item.id} className="info-label">
              {item.label}
            </label>
            <div id={item.id} className="info-field">
              {data[item.id]}
            </div>
          </div>
        </GridRow>
      ))}
    </Grid>
  );
};

export default Info;
