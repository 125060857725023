import "./register.scss";
import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../../common/Footer/footer";
import Navbar from "../../common/Navbar/navbar";

import authModel from "../../models/authModel";

import Form from "../../common/Form/form";
import LinkContainer from "../../common/LinkContainer/linkContainer";

import fieldList from "./fieldList.json"

const Register = () => {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null)
  const [error, setError] = useState(null);

  const registerUser = authModel.useRegister()
  const navigate = useNavigate();

  const submit = () => {
    setData(data)
    registerUser.fetch(data)
  }

  useEffect(() => {
    let newData = {}
    fieldList.map(item => { if (item.type !== "submit") newData = { ...newData, [item.id]: null } })
    setData(newData)
  }, [])

  useEffect(() => {
    if (registerUser.res.status === "error") setError(true);
    if (registerUser.res.status === "load") navigate("/login");
  }, [registerUser.res.status]);

  useEffect(() => {
    if (data.confirmPassword !== null && data.confirmPassword !== data.password) setMessage("* Ambas contraseñas deben coincidir")
    else setMessage(null)
  }, [data]);


  return (
    <Fragment>
      <Navbar />
      <div className="register">
        <div className="register-container">
          <div className="register-title">Datos del Usuario</div>
          {error ? (
            <small className="register-message">
              * Error al crear el usuario
            </small>
          ) : null}

          {message ? <small className="register-message">
            {message}
          </small> : null}

          <Form
            list={fieldList}
            data={data}
            setData={setData}
            submit={submit}
          />

          <div className="register-link-container">
            <LinkContainer
              message="Ya esta registrado? Iniciar Sesion"
              path="/login"
            />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Register;
