import React from 'react'
import "./linkWithIcon.scss"
import { Link } from 'react-router-dom'

export default function LinkWithIcon({text,icon,path}) {
  return (
      <div className='link-icon-content'>
          <Link to={path} className="link-icon-container-link">
          <img className="link-icon-img" src={icon} />
              <span className="link-icon-link">{text}</span>
          </Link>
      </div>
  )
}
