import "./assets/css/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import PrivateRoute from "./common/PrivateRoute/privateRoute";

import Home from "./pages/Home/home";
import Login from "./pages/Login/login";
import Register from "./pages/Register/register";
import Test from "./pages/Test/test";

ReactDOM.render(
  <Router>
    <Routes>
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/test" element={<Test />} />
      <Route path="*" element={<Navigate replace to="/home" />} />
    </Routes>
  </Router>,

  document.getElementById("root")
);
