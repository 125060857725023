import "./formDate.scss";
import classnames from "classnames";

const FormDate = ({
  id,
  label,
  labelPosition,
  value,
  min,
  max,
  required,
  onChange,
}) => {
  return (
    <div className="form-date-control">
      <label htmlFor={id} className="form-date-label">
        {label}
      </label>

      <input
        className={classnames("form-date-field", {
          "form-date-field-on": labelPosition === "upon",
        })}
        id={id}
        type="date"
        value={value ? value : ""}
        onChange={(event) => onChange(id, event.target.value)}
        min={min}
        max={max}
        required={required}
      />
    </div>
  );
};

export default FormDate;
