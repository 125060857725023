import "./modalDecision.scss";
import classnames from "classnames";
import Modal from "../Modal/modal";
import { Grid, GridRow } from "../Grid/grid";
import Button from "../Button/button";

import { BsCheckCircle } from "react-icons/bs";
import { VscError } from "react-icons/vsc";
import { AiOutlineWarning } from "react-icons/ai"

const ModalDecision = ({ type, setToggle, message, accept, discard }) => {
  
  const onAccept = () => {
    if(accept) accept();
    setToggle();
  }

  const onDiscard = () => {
    if(discard) discard();
    setToggle();
  }

  return (
    <Modal width="600px" height="360px" setToggle={setToggle}>
      <div className="modal-decision-content">
        <div className="modal-decision-message-content">
        <div
          className={classnames("modal-decision", {
            "modal-decision-success": type === "success",
            "modal-decision-warning": type === "warning",
            "modal-decision-error": type === "error",
          })}
        >
          <div className="modal-decision-icon">
            {type === "success" ? <BsCheckCircle /> : null}
            {type === "warning" ? <AiOutlineWarning /> : null}
            {type === "error" ? <VscError /> : null}
          </div>
          {message}
        </div>
        </div>

        <Grid >
        <GridRow width={1}></GridRow>
          <GridRow width={5}>
            <Button
              id="yes"
              label="Si"
              color="grey"
              type="button"
              handler={() => onAccept()}
            />
          </GridRow>
          <GridRow width={5}>
            <Button
              id="no"
              label="No"
              color="grey"
              type="button"
              handler={() => onDiscard()}
            />
          </GridRow>
          <GridRow width={1}></GridRow>
        </Grid>
      </div>
    </Modal>
  );
};

export default ModalDecision;
