import "./userManagerEditApiKeys.scss";
import { useState, useEffect, Fragment } from "react";

import Modal from "../../../common/Modal/modal"
import Loader from "../../../common/Loader/loader";
import ApiKeysTable from "../../../custom/ApiKeysTable/apiKeysTable";

import apiKeysModel from "../../../models/apiKeysModel";
import ModalMessage from "../../../common/ModalMessage/modalMessage";
import ModalDecision from "../../../common/ModalDecision/modalDecision";
import Form from "../../../common/Form/form";

import listFieldsJSONEdit from "./userManagerEditApiKeys.json"

import listFieldsJSONAdd from "./userManagerAddApiKeys.json"


const UserManagerEditApiKeys = ({ userId, editUser }) => {
  const [apiKeys, setApiKeys] = useState(null);                // Guarda el estado de un usuario


  const [topModalMessage, setTopModalMessage] = useState(null);
  const [topModalDecision, setTopModalDecision] = useState(null);
  const [editApiKey, setEditApiKey] = useState(null);

  //ADD API KEY
  const [addApiKeyData, setAddApiKeyData] = useState({
    name: "",
    expirationDate: "",
    userId
  })
  const [addApiKeyModal, setAddApiKeyModal] = useState(false);

  const getApiKeys = apiKeysModel.useGet();                    // Hook que permite consultar el estado de un usuario
  const removeApiKey = apiKeysModel.useRemove();
  const updateApiKey = apiKeysModel.useUpdate();
  const addApiKey = apiKeysModel.useAdd();

  // Al cargar el componente se consulta el usuario, la lista de roles y la lista de estados
  useEffect(() => {
    getApiKeys.fetch(userId)
  }, []);

  // Recibo la respuesta de la consulta del usuario
  useEffect(() => {
    if (getApiKeys.res.status === "load") {
      let data = getApiKeys.res.data
      setApiKeys(data)
    };
  }, [getApiKeys.res.status]);

  // Recibo la respuesta de la consulta de eliminacion de apiKey
  useEffect(() => {
    if (removeApiKey.res.status === "load") {
      setTopModalMessage({
        type: "success",
        message: "Api Key eliminado exitosamente",
      });
      const newApiKey = apiKeys.filter((item) => item.id !== removeApiKey.res.data.id);
      setApiKeys(newApiKey)
    }
    if (removeApiKey.res.status === "error") {
      setTopModalMessage({
        type: "error",
        message: "Error al eliminar el usuario",
      });
    }
  }, [removeApiKey.res.status]);

  // Recibo la respuesta de la consulta de edicion de apiKey
  useEffect(() => {
    if (updateApiKey.res.status === "load") {
      setTopModalMessage({
        type: "success",
        message: "Api Key editado exitosamente",
      });
      const newApiKey = apiKeys.map((item) => item.id === updateApiKey.res.data.id ? updateApiKey.res.data : item);
      setApiKeys(newApiKey)
    }
    if (updateApiKey.res.status === "error") {
      setTopModalMessage({
        type: "error",
        message: "Error al editar el usuario",
      });
    }
    setEditApiKey(null)
  }, [updateApiKey.res.status]);

  // Recibo la respuesta de la consulta de creacion de apiKey
  useEffect(() => {
    if (addApiKey.res.status === "load") {
      setTopModalMessage({
        type: "success",
        message: "Api Key creado exitosamente",
      });
      const newApiKey = [...apiKeys, addApiKey.res.data]
      setApiKeys(newApiKey)
    }
    if (addApiKey.res.status === "error") {
      setTopModalMessage({
        type: "error",
        message: "Error al crear Api Key",
      });
    }
    setAddApiKeyModal(false)
    setAddApiKeyData({
      name: "",
      expirationDate: "",
      userId
    })
  }, [addApiKey.res.status]);

  const deleteApiKey = (id) => {
    setTopModalDecision({
      type: "warning",
      message: "¿Está seguro que desea eliminar el Api Key?",
      accept: () => removeApiKey.fetch(id),
    });
  };

  const submitUpdateApiKey = () => {
    updateApiKey.fetch(editApiKey.id, editApiKey)
  }

  const submitAddApiKey = () => {
    addApiKey.fetch(addApiKeyData)
  }


  return (
    <Modal width="700px" setToggle={editUser}>
      <div className="edit-user-form-content">
        {apiKeys ? (
          <Fragment>
            <ApiKeysTable
              apiKeysList={apiKeys}
              modalMessage={setTopModalMessage}
              modalDecision={setTopModalDecision}
              deleteApiKey={deleteApiKey}
              editApiKey={setEditApiKey}
              addApiKey={setAddApiKeyModal}
            />
            {
              editApiKey &&
              <Modal width="400px" height="210px" setToggle={() => setEditApiKey(null)}>
                <div className="edit-user-form-content">
                  <Form
                    list={listFieldsJSONEdit}
                    data={editApiKey}
                    setData={((apiKey) => {
                      //iterate through apiKeys array and change de one that id is equal to apiKey.id
                      setEditApiKey(apiKey)
                    })}
                    submit={() => submitUpdateApiKey(editApiKey)}
                    cancel={() => setEditApiKey(null)}
                  />
                </div>
              </Modal>
            }
            {
              addApiKeyModal &&
              <Modal width="400px" height="210px" setToggle={() => setAddApiKeyModal(false)}>
                <div className="edit-user-form-content">
                  <Form
                    list={listFieldsJSONAdd}
                    data={addApiKeyData}
                    setData={((apiKey) => {
                      //iterate through apiKeys array and change de one that id is equal to apiKey.id
                      setAddApiKeyData(apiKey)
                    })}
                    submit={() => submitAddApiKey(addApiKeyData)}
                    cancel={() => setAddApiKeyModal(false)}
                  />
                </div>
              </Modal>
            }
            {topModalMessage &&
              <ModalMessage
                type={topModalMessage.type}
                setToggle={setTopModalMessage}
                message={topModalMessage.message}
              />}
            {topModalDecision &&
              <ModalDecision
                type={topModalDecision.type}
                setToggle={setTopModalDecision}
                message={topModalDecision.message}
                accept={topModalDecision.accept}
              />}
          </Fragment>
        ) : (
          <div>
            <Loader size="big" />
          </div>
        )}

      </div>
    </Modal>
  );
};

export default UserManagerEditApiKeys;
