import "./tableSearch.scss";
import classnames from "classnames";
import { AiOutlineSearch } from "react-icons/ai"

const TableSearch = ({ id, label, labelPosition, size, placeholder, value, onSearch }) => {
  return (
    <div className="table-search-control">
      {label?      <label htmlFor={id} className="table-search-label">
        {label}
      </label>:null}


      <div className={
        classnames( "table-search-field", 
          {
            "table-search-field-on": labelPosition === "upon",
            "table-search-field-medium": size === "medium",
            "table-search-field-big": size === "big"
          }
        )}>
        <input
          className={classnames("table-search-input", 
            {
              "table-search-input-medium": size === "medium",
              "table-search-input-big": size === "big"
            }
          )}
          id={id}
          type="text"
          placeholder={placeholder}
          value={value ? value : ""}
          onChange={(event) => onSearch(event.target.value)}
        />

        <div className={classnames("table-search-icon",
          {
            "table-search-icon-medium": size === "medium",
            "table-search-icon-big": size === "big"
          }
        )}>
          <AiOutlineSearch/>
        </div>
      </div>

    </div>
  );
};

export default TableSearch;
