import "./customForm.scss"
import { Fragment, useEffect } from "react";
import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const CustomForm = ({ data, setData, submit, cancel }) => {

    const [show, setShow] = useState(false);

    const handleChangeInput = (event) => {
        const { name, value } = event.target;
        saveData(name, value);
    }

    const saveData = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const onsubmitHandler = (event) => {
        event.preventDefault();
        submit();
    };

    return (
        <form className="custom-form-container" onSubmit={onsubmitHandler}>
            <div className="custom-form-input-control">
                <input
                    type="email"
                    placeholder="Email"
                    className="custom-form-input-field"
                    name="mail"
                    value={data.mail}
                    onChange={handleChangeInput}
                    required
                />
            </div>
            <div className="custom-form-password-field">
                <input
                    type={show ? "text" : "password"} 
                    placeholder="Contraseña"
                    className="custom-form-password-input"
                    name="password"
                    value={data.password}
                    onChange={handleChangeInput}
                    required
                />
                <div className="custom-form-password-icon" onClick={() => setShow(!show)}>
                    {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
            </div>
            <button
                type="submit"
                className="custom-form-button custom-form-button-green"
                onClick={() => { }}
            >
                INGRESAR
            </button>
        </form>
    );
};

export default CustomForm;
