import "./form.scss";
import { Fragment, useEffect } from "react";
import { Grid, GridRow } from "../Grid/grid";

import FormSelect from "../FormSelect/formSelect";
import FormMultiSelect from "../FormMultiSelect/formMultiSelect";
import FormInput from "../FormInput/formInput";
import FormPassword from "../FormPassword/formPassword";
import FormChoise from "../FormChoise/formChoise";
import FormFile from "../FormFile/formFile";
import FormMessage from "../FormMessage/formMessage";
import FormButton from "../FormButton/formButton";
import FormDate from "../FormDate/formDate";

const Form = ({ list, data, setData, submit, cancel }) => {
  const saveData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const onsubmitHandler = (event) => {
    event.preventDefault();
    submit();
  };

  return (
    <form className="form-cotainer" onSubmit={onsubmitHandler}>
      <Grid>
        {list.map((item) => (
          <GridRow key={item.id} width={item.width}>
            {item.dependsOn === undefined || item.dependsOn ? (
              <Fragment>
                {item.type === "select" ? (
                  <FormSelect
                    id={item.id}
                    label={item.label}
                    labelPosition={item.labelPosition}
                    defaultValue={data[item.id]}
                    options={item.options}
                    onChange={saveData}
                  />
                ) : null}
                {item.type === "multi-select" ? (
                  <FormMultiSelect
                    id={item.id}
                    label={item.label}
                    labelPosition={item.labelPosition}
                    defaultValue={data[item.id]}
                    options={item.options}
                    onChange={saveData}
                  />
                ) : null}
                {item.type === "date" ? (
                  <FormDate
                    id={item.id}
                    label={item.label}
                    labelPosition={item.labelPosition}
                    value={data[item.id]}
                    min={item.min}
                    max={item.max}
                    onChange={saveData}
                  />
                ) : null}
                {item.type === "text" ? (
                  <FormInput
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    labelPosition={item.labelPosition}
                    placeholder={item.placeholder}
                    value={data[item.id]}
                    message={item.message}
                    required={item.required}
                    onChange={saveData}
                  />
                ) : null}
                {item.type === "password" ? (
                  <FormPassword
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    labelPosition={item.labelPosition}
                    placeholder={item.placeholder}
                    value={data ? data[item.id] : ""}
                    message={item.message}
                    required={item.required}
                    onChange={saveData}
                    shownable={item.shownable}
                  />
                ) : null}
                {item.type === "choise" ? (
                  <FormChoise
                    id={item.id}
                    options={item.options}
                    choise={data[item.id]}
                    onChange={saveData}
                  />
                ) : null}
                {item.type === "file" ? (
                  <FormFile
                    id={item.id}
                    defaultFile={data[item.id]}
                    onChange={saveData}
                  />
                ) : null}
                {item.type === "message" ? (
                  <FormMessage
                    id={item.id}
                    label={item.label}
                    size={item.size}
                    criticality={item.criticality}
                  />
                ) : null}
                {item.type === "empty" ? <div></div> : null}
                {item.type === "button" ? (
                  <FormButton
                    id={item.id}
                    label={item.label}
                    color={item.color}
                    type="button"
                    onClick={item.onClick}
                    disabled={item.disabled}
                  />
                ) : null}
                {item.type === "submit" ? (
                  <FormButton
                    id={item.id}
                    label={item.label}
                    color={item.color}
                    type="submit"
                  />
                ) : null}
                {item.type === "cancel" ? (
                  <FormButton
                    id={item.id}
                    label={item.label}
                    color={item.color}
                    type="button"
                    onClick={cancel}
                    disabled={item.disabled}
                  />
                ) : null}
              </Fragment>
            ) : (
              <div></div>
            )}
          </GridRow>
        ))}
      </Grid>
    </form>
  );
};

export default Form;
