import "./editUserPassword.scss";
import { useState, useEffect, Fragment } from "react";

import Modal from "../../../common/Modal/modal"
import Form from "../../../common/Form/form";
import Loader from "../../../common/Loader/loader";

import userModel from "../../../models/userModel";
import editUserPassword from "./editUserPassword.json"

const EditUserPassword = ({ userId, editUser, setModalMessage }) => {
  const [user, setUser] = useState(null);                   // Guarda el estado de un usuario
  const [fields, setFields] = useState(editUserPassword);   // Guarda los campos del formulario de edicion de password
  const [message, setMessage] = useState("");               // Guarda los mensajes de error

  const updateUser = userModel.useUpdate();                 // Hook que permite actualizar un usuario

  // Recibo la respuesta de actualizacion de usuario
  useEffect(() => {
    if (updateUser.res.status === "load") {
      editUser();
      setModalMessage({type:"success", message: "Contraseña actualizada exitosamente"});
    } else if (updateUser.res.status === "error"){
      editUser();
      setModalMessage({type:"error", message: "Error al actualizar la contraseña"});
    }
  }, [updateUser.res.status]);

  // Funcion de actulizacion de usuario
  const submit = () => {
    updateUser.fetch(user)
  }

  // Verifica la coincidencia de las contraseñas
  useEffect(() => {
    if(user && user.password !== user.confirm_password) setMessage("* Las contraseñas deben coincidir.")
    if(user && user.password === user.confirm_password) setMessage("")
  }, [user])

  // Modifica los campos del formulario para mostrar mensajes de error
  useEffect(() => {
      const newFields = fields.map(item => (item.id === "message"? {...item, label: message}:item))
      setFields(newFields)
  }, [message])

  return (
    <Modal width="400px" height="230px" setToggle={editUser}>
      <div className="edit-user-password-content">
        <Form
          list={fields}
          data={user}
          setData={setUser}
          submit={submit}
          cancel={editUser}
        />
      </div>
    </Modal>
  );
};

export default EditUserPassword;
