import "./tableList.scss";
import { Fragment } from "react";
import Button from "../Button/button";

import { BsTrash } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";

const TableList = ({
  colList,
  rowList,
  getItem,
  deleteItem,
  editItem,
  createItem,
  disblaeCreate,
}) => {
  return (
    <Fragment>
      <div className="menu-table-container">
        <table className="menu-table-table">
          <thead>
            <tr>
              {colList.map((col) => (
                <th key={col.id}>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowList.map((row) => (
              <tr key={row.id} className="menu-table-item">
                {colList.map((col) => (
                  <td
                    key={`${row.id}-${col.id}`}
                    onClick={() => {
                      getItem(row.id);
                    }}
                  >
                    {row[col.id]}
                  </td>
                ))}
                <td
                  key={`${row.id}-edit`}
                  className="menu-table-item-edit"
                  onClick={() => editItem(row.id)}
                >
                  <HiPencilAlt />
                </td>

                <td
                  key={`${row.id}-delete`}
                  className="menu-table-item-delete"
                  onClick={() => deleteItem(row.id)}
                >
                  <BsTrash />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="menu-table-button">
        <Button
          id="new_client"
          label="Nuevo"
          column={1}
          color="green"
          type="button"
          handler={createItem}
          disabled={disblaeCreate}
        />
      </div>
    </Fragment>
  );
};

export default TableList;
