import "./home.scss";
import React, { Fragment, useEffect, useState } from "react";
import userModel from "../../models/userModel";

import Navbar from "../../common/Navbar/navbar";
import Menu from "../../common/Menu/menu";
import MiniMenu from "../../common/MiniMenu/miniMenu";
import MapExample from "../../common/MapExample/mapExample";
import ModalMessage from "../../common/ModalMessage/modalMessage";
import ModalDecision from "../../common/ModalDecision/modalDecision";

import NewFarm from "../../components/RemoteSensing/NewFarm/newFarm";
import NewClient from "../../components/RemoteSensing/NewClient/newClient";
import User from "../../components/RemoteSensing/User/user";
import Client from "../../components/RemoteSensing/Client/client";
import Farm from "../../components/RemoteSensing/Farm/farm";

import UserManager from "../../components/UserProfiles/UserManager/userManager";
import UserManagerEdit from "../../components/UserProfiles/UserManagerEdit/userManagerEdit";
import UserManagerEditPassword from "../../components/UserProfiles/UserManagerEditPassword/userManagerEditPassword";

import Analitics from "../../components/Analitics/analitics";

import EditUser from "../../components/MyProfile/EditUser/editUser";
import EditUserPassword from "../../components/MyProfile/EditUserPassword/editUserPassword";

import userAuth from "../../hooks/userAuth";

import { IoPeople } from "react-icons/io5";
import { VscRemoteExplorer, VscGraphLine } from "react-icons/vsc";
import UserManagerEditApiKeys from "../../components/UserProfiles/UserManagerEditApiKeys/userManagerEditApiKeys";
import ShowApiKeys from "../../components/MyProfile/ShowApiKeys/showApiKeys";

function Home() {
  const [item, setItem] = useState({
    userId: null,
    clientId: null,
    farmId: null,
  });
  const [menu, setMenu] = useState("user");
  const [section, setSection] = useState("");

  const [user, setUser] = useState(null);
  const [isRSDemo, setIsRSDemo] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [center, setCenter] = useState([-31, -59]);
  const [polygon, setPolygon] = useState(null);
  const [fixedPolygon, setFixedPolygon] = useState([]);
  const [layer, setLayer] = useState([]);
  const [drawable, setDrawable] = useState(false);
  const [clear, setClear] = useState(true);

  const [showDropdown, setShowDropdown] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalDecision, setModalDecision] = useState(null);
  const [hideControl, siteHideControl] = useState(false);

  const [editUser, setEditUser] = useState(false);
  const [editUserPassword, setEditUserPassword] = useState(null);

  const [showApiKeys, setShowApiKeys] = useState(null);

  const [userManagerEdit, setUserManagerEdit] = useState(null);
  const [userManagerEditApiKeys, setUserManagerEditApiKeys] = useState(null);
  const [userManagerEditPassword, setUserManagerEditPassword] = useState(null);

  const getUser = userModel.useGet();

  const setItemId = (type, id) => {
    if (type === "user") setItem({ ...item, userId: id });
    if (type === "client") setItem({ ...item, clientId: id });
    if (type === "farm") setItem({ ...item, farmId: id });
  };

  useEffect(() => {
    const id = userAuth.getUserId();
    setItemId("user", id);
  }, []);

  useEffect(() => {
    if (item.userId) getUser.fetch();
  }, [item.userId]);

  useEffect(() => {
    if (getUser.res.status === "load") setUser(getUser.res.data);
  }, [getUser.res.status]);

  const map = () => {
    return (
      <div className="map-container">
        <MapExample
          center={center}
          zoom={6}
          polygon={polygon}
          setPolygon={setPolygon}
          fixedPolygon={fixedPolygon}
          showCoordinates={menu == "farm" ? true : false}
          layerPolygon={layer}
          drawable={drawable}
          clear={clear}
          hideControl={hideControl}
        />
      </div>
    );
  };

  const onEditUser = () => {
    setEditUser(!editUser);
    siteHideControl(!hideControl);
    setShowDropdown(false);
  };

  useEffect(() => {
    setItemList([
      {
        id: "user-manager",
        icon: <IoPeople />,
        dependsOn:
          user && user.role.find((role) => role.id === 1) !== undefined,
      },
      {
        id: "analitics",
        icon: <VscGraphLine />,
        dependsOn:
          user && user.role.find((role) => role.id === 1) !== undefined,
      },
      {
        id: "remote-sensing",
        icon: <VscRemoteExplorer />,
        dependsOn:
          user &&
          user.role.find((role) => role.id === 2 || role.id === 5) !==
            undefined,
      },
    ]);
    if (user && user.role.find((role) => role.id === 5) !== undefined) {
      setSection("remote-sensing");
      setIsRSDemo(true);
    }
    if (user && user.role.find((role) => role.id === 2) !== undefined) {
      setSection("remote-sensing");
      setIsRSDemo(false);
    }
    if (user && user.role.find((role) => role.id === 1) !== undefined) {
      setSection("user-manager");
    }
  }, [user]);

  return (
    <Fragment>
      <Navbar
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        editUser={onEditUser}
        setEditPassword={setEditUserPassword}
        setShowApiKeys={setShowApiKeys}
        user={user}
      />
      <MiniMenu itemList={itemList} section={section} setSection={setSection}>
        {section === "remote-sensing" ? (
          <Menu content={map()}>
            {menu == "user" ? (
              <User
                setMenu={setMenu}
                setItemId={(type, id) => setItemId(type, id)}
                userId={item.userId}
                isDemo={isRSDemo}
                setModalMessage={setModalMessage}
                setModalDecision={setModalDecision}
              />
            ) : null}
            {menu == "client" ? (
              <Client
                setMenu={setMenu}
                setItemId={(type, id) => setItemId(type, id)}
                clientId={item.clientId}
                userId={item.userId}
                setPolygon={setFixedPolygon}
                isDemo={isRSDemo}
                setModalMessage={setModalMessage}
                setModalDecision={setModalDecision}
              />
            ) : null}
            {menu == "newClient" ? (
              <NewClient
                setMenu={setMenu}
                clientId={item.clientId}
                userId={item.userId}
                setModalMessage={setModalMessage}
              />
            ) : null}
            {menu == "farm" ? (
              <Farm
                setMenu={setMenu}
                farmId={item.farmId}
                clientId={item.clientId}
                setLayer={setLayer}
                polygon={fixedPolygon}
                setPolygon={setFixedPolygon}
                setModalMessage={setModalMessage}
              />
            ) : null}
            {menu == "newFarm" ? (
              <NewFarm
                clientId={item.clientId}
                polygon={polygon}
                setMenu={setMenu}
                setPolygon={setPolygon}
                setDrawable={setDrawable}
                farmId={item.farmId}
                setModalMessage={setModalMessage}
              />
            ) : null}
          </Menu>
        ) : null}
        {modalMessage ? (
          <ModalMessage
            type={modalMessage.type}
            setToggle={setModalMessage}
            message={modalMessage.message}
          />
        ) : null}
        {modalDecision ? (
          <ModalDecision
            type={modalDecision.type}
            setToggle={setModalDecision}
            message={modalDecision.message}
            accept={modalDecision.accept}
          />
        ) : null}
        {section === "user-manager" ? (
          <UserManager
            edit={userManagerEdit}
            setEditApiKeys={setUserManagerEditApiKeys}
            setEdit={setUserManagerEdit}
            setEditPassword={setUserManagerEditPassword}
            setModalMessage={setModalMessage}
            setModalDecision={setModalDecision}
          />
        ) : null}
        {section === "analitics" ? <Analitics /> : null}
        {userManagerEditApiKeys ? (
          <UserManagerEditApiKeys
            userId={userManagerEditApiKeys}
            editUser={() => setUserManagerEditApiKeys(null)}
            setModalMessage={setModalMessage}
          />
        ) : null}
        {userManagerEdit ? (
          <UserManagerEdit
            userId={userManagerEdit}
            editUser={() => setUserManagerEdit(null)}
            setModalMessage={setModalMessage}
          />
        ) : null}
        {userManagerEditPassword ? (
          <UserManagerEditPassword
            userId={userManagerEditPassword}
            editUser={() => setUserManagerEditPassword(null)}
            setModalMessage={setModalMessage}
          />
        ) : null}
        {editUserPassword ? (
          <EditUserPassword
          userId={editUserPassword}
          editUser={() => setEditUserPassword(null)}
          setModalMessage={setModalMessage}
          />
        ) : null}
        {editUser ? (
          <EditUser
          userId={item.userId}
          editUser={onEditUser}
          setModalMessage={setModalMessage}
          />
        ) : null}
        {
          showApiKeys &&
          <ShowApiKeys
            userId={item.userId}
            editApiKey={()=> setShowApiKeys(null)}
            setModalMessage={setModalMessage}
          />
        }
      </MiniMenu>
    </Fragment>
  );
}

export default Home;
