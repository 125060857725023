import "./formFile.scss";
import { Fragment, useEffect, useRef } from "react";

import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

function FormFiles({ id, defaultFile, onChange }) {
  const drop = useRef(null);

  useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("drop", handleDrop);
    }
  }, [drop]);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;

    if (files && files.length) {
      const file = files[0];
      onChange(id, file);
    }
  };

  const handleInput = (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files && files.length) {
      const file = files[0];
      onChange(id, file);
    }
  };

  const handleDelete = () => {
    onChange(id, null)
  }

  return (
    <Fragment>
      {defaultFile ?
        <div className="form-file-table-content">
          <table className="form-file-table">
            <tbody>
              <tr key={defaultFile.id} className="form-file-row">
                <td className="form-file-name">{defaultFile.name}</td>
                <td className="form-file-delete">
                  <div
                    className="form-file-delete-icon"
                    onClick={handleDelete}
                  >
                    <FaTimes />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> :
        <Fragment> <input
          id={id}
          type="file"
          text="file"
          className="form-file-input"
          onChange={(event) => handleInput(event)}
        />
          <label ref={drop} htmlFor={id} className="form-file-label">
            <div className="form-file-text"> Hey, drop me some files</div>
            <div className="form-file-download-icon">
              <AiOutlineCloudUpload />
            </div>
          </label>
        </Fragment>}
    </Fragment>
  );
}

export default FormFiles;
