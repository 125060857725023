import "./formInput.scss";
import classnames from "classnames";

const FormInput = ({ id, label, type, labelPosition, placeholder, value, msg_condition, message, required, onChange }) => {
  return (
    <div className="form-input-control">
      <label htmlFor={id} className="form-input-label">
        {label}
      </label>

      <input
        className={classnames("form-input-field", { "form-input-field-on": labelPosition === "upon" })}
        id={id}
        type={type}
        placeholder={placeholder}
        value={value ? value : ""}
        onChange={(event) => onChange(id, event.target.value)}
        required={required}
      />

      {message ? (
        <small className="input-format-message">{message}</small>
      ) : null}
    </div>
  );
};

export default FormInput;
