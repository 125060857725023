import "./showApiKeys.scss";
import { useState, useEffect, Fragment, useReducer } from "react";

import Loader from "../../../common/Loader/loader";
import Modal from "../../../common/Modal/modal"

import userModel from "../../../models/userModel";
import apiKeysModel from "../../../models/apiKeysModel";
import UserApiKeysTable from "../../../custom/UserApiKeysTable/userApiKeysTable";

const ShowApiKeys = ({ userId, editApiKey, setModalMessage }) => {
  const [apiKeys, setApiKeys] = useState(null);       // Guarda el estado de un usuario

  const getUserApiKeys = apiKeysModel.useGet();        // Hook que permite consultar un usuario

  // Al cargar el componente se consulta el usuario
  useEffect(() => {
    getUserApiKeys.fetch("")
  }, []);

  // Recibo la respuesta de la consulta del usuario
  useEffect(() => {
    if (getUserApiKeys.res.status === "load") {
      let data = getUserApiKeys.res.data
      setApiKeys(data)
    };
  }, [getUserApiKeys.res.status]);



  return (
    <Fragment>
      <Modal width="675px" setToggle={editApiKey}>
        <div className="edit-user-form-content">
          {
            (apiKeys) ?
              <Fragment>
                <UserApiKeysTable
                  apiKeysList={apiKeys}
                />
              </Fragment>

              : <Loader size="big" />
          }
        </div>

      </Modal>
    </Fragment>
  );
};

export default ShowApiKeys;
