import { useState, useEffect } from "react";
import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_REPORT_MANAGER}`,
  timeout: 180000,
  headers: {
    "Content-Type": "application/json",
  },
});

const useExcelReport = () => {
  const [res, setRes] = useState({ status: "", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data, params) => {
    setParams(params);
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/excel/${params.band}`, body, {
          responseType: "blob",
        })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const usePDFReport = () => {
  const [res, setRes] = useState({ status: "", msg: null, data: null });
  const [queue, setQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const addToQueue = (data, params) => {
    setQueue(prevQueue => [...prevQueue, { data, params }]);
  };

  const processNextInQueue = () => {
    setQueue(prevQueue => prevQueue.slice(1));
    setIsProcessing(false);
  };

  useEffect(() => {
    if (queue.length > 0 && !isProcessing) {
      const { data, params } = queue[0];
      setIsProcessing(true);
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/api/report?norm=${params.norm}&lang=${params.lang || 'es'}`, data, {
          responseType: "blob",
        })
        .then((res) => {
          setRes({
            status: "load",
            msg: null,
            data: { report: res.data, norm: params.norm, lang: params.lang || 'es' },
          });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err.message, data: null });
        });
    }
  }, [queue, isProcessing]);

  return { res, addToQueue, processNextInQueue };
};

export default {
  useExcelReport,
  usePDFReport,
};
