import "./test.scss";
import { Grid, GridRow } from "../../common/Grid/grid";
import classNames from "classnames";

import roleModel from "../../models/roleModel";
import statusModel from "../../models/statusModel";

import FormPassword from "../../common/FormPassword/formPassword"
import FormMessage from "../../common/FormMessage/formMessage"

import ColorField from "../../common/ColorField/colorField"

import { Fragment, useEffect, useState } from "react";

const Test = () => {
  const [data, setData] = useState({})

  const saveData = (key, value) => {
    setData({ ...data, [key]: value })
  }

  useEffect(()=>{
    console.log(data)
  }, [data])

  return (
    <Fragment>
        <Grid >
          <GridRow width={6}>
            <FormPassword
              id={"role"}
              label={"Role"}
              labelPosition={"upon"}
              onChange={saveData}
              placeholder="password"
              value={data["role"]}
              shownable
            />
          </GridRow>
          <GridRow width={11}>
            <FormMessage 
              id={"message"}
              label="* Ambos campos deben coincidir"
              size="small"
              criticality="warning"
            />
          </GridRow>
        </Grid>
    </Fragment>

  )
};

export default Test;
