import "./newFarm.scss";
import React, { Fragment, useState, useEffect } from "react";
import uuid from "react-uuid";

import Form from "../../../common/Form/form";

import farmModel from "../../../models/farmModel";
import integralabsModel from "../../../models/integralabsModel";

import fieldList from "./fieldList.json";

const Farms = ({
  clientId,
  polygon,
  setMenu,
  setPolygon,
  setDrawable,
  farmId,
  setModalMessage,
}) => {
  const [farm, setFarm] = useState({ choise: "file" });
  const [farmList, setFarmList] = useState([]);

  const getFarm = farmModel.useGet();
  const updateFarm = farmModel.useUpdate();
  const createFarm = farmModel.useAdd();

  const kmzFile = integralabsModel.useConvertFromKMZ();
  const kmlFile = integralabsModel.useConvertFromKML();

  const truncateCoordinates = function (coordinates) {
    coordinates = coordinates.map((coordinateList) => {
      return coordinateList.map((coordinate) => {
        if (coordinate.length > 2) {
          return coordinate.slice(0, 2);
        } else {
          return coordinate;
        }
      });
    });
    return coordinates;
  };

  const processFile = (res) => {
    if (Array.isArray(res)) {
      let item = res[0];
      if (item.type === "FeatureCollection" && item.features.length > 1) {
        setModalMessage({
          type: "error",
          message: "El archivo seleccionado contiene multiples polygonos",
        });
        setPolygon(null);
      } else {
        item = item.features[0];
        item.properties.id = uuid();
        item.geometry.coordinates = truncateCoordinates(
          item.geometry.coordinates
        );
        setPolygon(item);
      }
    } else if (res.type === "FeatureCollection") {
      setModalMessage({
        type: "error",
        message: "El archivo seleccionado contiene multiples polygonos",
      });
      setPolygon(null);
    } else {
      res.properties.id = uuid();
      res.geometry.coordinates = truncateCoordinates(res.geometry.coordinates);
      setPolygon(res);
    }
  };

  useEffect(() => {
    let defaultData = {};
    fieldList
      .filter((item) => item.defaultValue !== undefined)
      .map((item) => {
        defaultData = { ...defaultData, [item.id]: item.defaultValue };
      });
    fieldList.map((item) => {
      if (item.id === "submit") item.label = "Nuevo";
    });
    setFarm(defaultData);
    setFarmList(fieldList);
  }, []);

  useEffect(() => {
    if (farmId) {
      getFarm.fetch(farmId);
      fieldList.map((item) => {
        if (item.id === "submit") item.label = "Editar";
      });
      setFarmList(fieldList);
    }
  }, [farmId]);

  useEffect(() => {
    if (farm.choise) {
      fieldList.map((item) => {
        if (item.id === "geoFile") item.dependsOn = farm.choise === "file";
      });
      setDrawable(farm.choise === "draw");
    }
  }, [farm.choise]);

  useEffect(() => {
    if (polygon) setFarm({ ...farm, geoPolynomial: JSON.stringify(polygon) });
  }, [polygon]);

  useEffect(() => {
    if (farm.geoFile) {
      if (farm.geoFile.name) {
        var data = new FormData();
        data.append("file", farm.geoFile);

        const extList = farm.geoFile.name.split(".");
        const ext = extList[extList.length - 1];
        if (ext.toLowerCase() == "kml") kmlFile.fetch(data);
        if (ext.toLowerCase() == "kmz") kmzFile.fetch(data);
      }
    } else {
      setPolygon(null);
    }
  }, [farm.geoFile]);

  useEffect(() => {
    if (kmzFile.res.status === "load") processFile(kmzFile.res.data);
  }, [kmzFile.res.status]);

  useEffect(() => {
    if (kmlFile.res.status === "load") processFile(kmlFile.res.data);
  }, [kmlFile.res.status]);

  useEffect(() => {
    if (getFarm.res.status === "load") {
      const newFarm = getFarm.res.data;
      setFarm({ ...newFarm, choise: "draw" });
      if (newFarm.geoPolynomial) setPolygon(JSON.parse(newFarm.geoPolynomial));
    }
    if (getFarm.res.status === "error") console.error(getFarm.res.msg);
  }, [getFarm.res.status]);

  useEffect(() => {
    if (updateFarm.res.status === "load") {
      setDrawable(false);
      setPolygon(null);
      setMenu("client");
      setModalMessage({
        type: "success",
        message: "Lote actualizado exitosamente",
      });
    }
    if (updateFarm.res.status === "error") {
      console.error(updateFarm.res.msg);
      setModalMessage({
        type: "error",
        message: `Error al actualizadar el lote.\n${updateFarm.res.msg}`,
      });
    }
  }, [updateFarm.res.status]);

  useEffect(() => {
    if (createFarm.res.status === "load") {
      setDrawable(false);
      setPolygon(null);
      setMenu("client");
      setModalMessage({ type: "success", message: "Lote creado exitosamente" });
    }
    if (createFarm.res.status === "error") {
      console.error(createFarm.res.msg);

      setModalMessage({
        type: "error",
        message: `Error al crear el lote.\n${createFarm.res.msg}`,
      });
    }
  }, [createFarm.res.status]);

  const validFarm = (farm) => {
    if (
      farm.name === null ||
      (farm.name !== undefined && farm.name.trim() === "")
    )
      return {
        status: false,
        message: "Por favor ingrese un nombre del lote",
      };
    else if (
      farm.geoPolynomial === undefined ||
      (farm.geoPolynomial !== undefined && farm.geoPolynomial.trim() === "")
    )
      return {
        status: false,
        message: "Por favor ingrese el polygono del lote",
      };
    else return { status: true };
  };

  const submit = () => {
    console.log(farm);
    const valid = validFarm(farm);
    if (valid.status) {
      if (farmId) updateFarm.fetch(farmId, { ...farm, clientId });
      else createFarm.fetch({ ...farm, clientId });
    } else {
      setFarm({ choise: "file" });
      if (farmId) getFarm.fetch(farmId);
      setModalMessage({ type: "warning", message: valid.message });
    }
  };

  const cancel = () => {
    setDrawable(false);
    setPolygon(null);
    setMenu("client");
  };

  return (
    <Fragment>
      <div className="new-farm-content">
        <h2>Nuevo Lote</h2>
        <Form
          list={farmList}
          data={farm}
          setData={setFarm}
          submit={submit}
          cancel={cancel}
        />
      </div>
    </Fragment>
  );
};

export default Farms;
