import "./buttons.scss";
import { Fragment } from "react";
import { RiLoader4Fill } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
import { TbFileCertificate, TbFileExport } from "react-icons/tb";

import { Grid, GridRow } from "../../common/Grid/grid";
import Button from "../../common/Button/button";

const BackButton = ({ goBack }) => {
  return (
    <div className="back-buctton-content">
      <Button
        id="back"
        label="Volver"
        column={1}
        color="blue"
        type="button"
        handler={goBack}
      />
    </div>
  );
};

const DownloadButton = ({ downloadPDF, loadingPDF, analingPDF }) => {
  const labelPDF = (
    <Fragment>
      {analingPDF ? (
        <Fragment>
          <div className="downloading-button-icon ">
            <RiLoader4Fill className="downloading-button-animation" />
          </div>
          <div className="download-button-text">Analizando lote...</div>
        </Fragment>
      ) : loadingPDF ? (
        <Fragment>
          <div className="downloading-button-icon ">
            <RiLoader4Fill className="downloading-button-animation" />
          </div>
          <div className="download-button-text">Descargando...</div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="download-button-icon">
            <TbFileCertificate />
          </div>
          <div className="download-button-text">Descargar Informe</div>
        </Fragment>
      )}
    </Fragment>
  );

  return (
    <div className="download-button-container">
      <Button
        id="export_pdf"
        label={labelPDF}
        disabled={loadingPDF || analingPDF}
        color="grey"
        type="button"
        handler={downloadPDF}
      />
    </div>
  );
};

export { BackButton, DownloadButton };
