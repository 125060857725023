import "./modal.scss";
import { Fragment } from "react";
import { FaTimes } from "react-icons/fa";

const Modal = ({ setToggle, width, height, children }) => {
  const style = {
    top: (height && width) ? `calc((100% - ${height} )/2)` : '25%',
    left: (height && width) ?`calc((100% - ${width} )/2)` : '25%',
    width: width,
    height: height,
  };
  return (
    <Fragment>
      <div className="modal-dialog" style={style}>
        <FaTimes className="modal-img-close" onClick={() => setToggle()} />
        {children}
      </div>
      <div className="modal-backdrop"></div>
    </Fragment>
  );
};

export default Modal;
