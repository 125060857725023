import "./user.scss";
import { Fragment, useEffect, useState } from "react";

import TableList from "../../../common/TableList/tableList";
import Submenu from "../../../common/Submenu/submenu";
import Loader from "../../../common/Loader/loader";

import colList from "./user.json";

import userModel from "../../../models/userModel";
import clientModel from "../../../models/clientModel";

import { Grid, GridRow } from "../../../common/Grid/grid";
import TableSearch from "../../../common/TableSearch/tableSearch";

const User = ({
  setMenu,
  setItemId,
  userId,
  isDemo,
  setModalMessage,
  setModalDecision,
}) => {
  const [user, setUser] = useState(null); // Guarda el estado del usuario
  const [clientList, setClientList] = useState([]); // Guarda la lista de productores de un usuario
  const [search, setSearch] = useState(""); // Guarda el string de busqueda

  const [clientMenu, setClientMenu] = useState(false);

  const getUser = userModel.useGet(); // Hook que permite la consulta de un usuario
  const getAllClients = clientModel.useGetAll(); // Hook que permite la consulta de la lista de productores
  const deleteClient = clientModel.useRemove(); // Hook que permite eliminar un productor

  // Consultamos usuario y lista de productores
  useEffect(() => {
    if (userId) {
      getUser.fetch();
      getAllClients.fetch(userId);
    }
  }, [userId]);

  // Recibo la respuesta de la consulta de usuario
  useEffect(() => {
    if (getUser.res.status === "load") setUser(getUser.res.data);
  }, [getUser.res.status]);

  // Recibo la respuesta de la consulta de lista de productores
  useEffect(() => {
    if (getAllClients.res.status === "load") {
      setClientList(getAllClients.res.data);
    }
  }, [getAllClients.res.status]);

  // Recibo la respuesta de la consulta de eliminacion de productor
  useEffect(() => {
    if (deleteClient.res.status === "load") {
      getAllClients.fetch(userId);
      setModalMessage({
        type: "success",
        message: "Productor eliminado exitosamente",
      });
    }
  }, [deleteClient.res.status]);

  // Funcion de creacion de nuevo productor
  const createItem = () => {
    setItemId("client", null);
    setMenu("newClient");
  };

  // Funcion de seleccion de un productor
  const getItem = (id) => {
    setItemId("client", id);
    setMenu("client");
  };

  // Funcion de eliminacion de un productor
  const deleteItem = (id) => {
    setModalDecision({
      type: "warning",
      message: "¿Está seguro que desea eliminar el productor?",
      accept: () => deleteClient.fetch(id),
    });
  };

  // Funcion de edicion de un productor
  const editItem = (id) => {
    setItemId("client", id);
    setMenu("newClient");
  };

  return (
    <div className="user-content">
      {getUser.res.status === "loading" || user === null ? (
        <div>
          <Loader size="big" />
        </div>
      ) : (
        <Fragment>
          <div className="user-title">{user.company}</div>
          <Submenu
            hideMenu={clientMenu}
            setHideMenu={setClientMenu}
            title={"Productores"}
          >
            {getAllClients.res.status === "loading" ? (
              <Loader size="small" />
            ) : (
              <Fragment>
                <Grid>
                  <GridRow width={6}></GridRow>
                  <GridRow width={5}>
                    <TableSearch
                      id="search-farm"
                      label=""
                      placeholder="Buscar"
                      labelPosition="side"
                      size="small"
                      value={search}
                      onSearch={setSearch}
                    />
                  </GridRow>
                </Grid>
                <TableList
                  colList={colList}
                  rowList={clientList.filter((item) =>
                    item.name.includes(search)
                  )}
                  createItem={createItem}
                  disblaeCreate={isDemo && clientList.length >= 5}
                  getItem={getItem}
                  editItem={editItem}
                  deleteItem={deleteItem}
                />
              </Fragment>
            )}
          </Submenu>
        </Fragment>
      )}
    </div>
  );
};

export default User;
