import "./formButton.scss";
import classnames from "classnames";
import React, { Fragment } from "react";

const FormButton = ({ id, label, color, type, onClick, disabled }) => {
  return (
    <Fragment>
      {type === "submit" ? (
        <button
          id={id}
          type={type}
          disabled={disabled}
          className={classnames("form-button", {
            "form-button-green": color === "green",
            "form-button-blue": color === "blue",
            "form-button-red": color === "red",
            "form-button-yellow": color === "yellow",
            "form-button-black": color === "black",
            "form-button-grey": color === "grey",
            "form-button-disable": disabled,
          })}
        >
          {label}
        </button>
      ) : (
        <button
          id={id}
          type={type}
          disabled={disabled}
          className={classnames("form-button", "form-button-hidden", {
            "form-button-green": color === "green",
            "form-button-blue": color === "blue",
            "form-button-red": color === "red",
            "form-button-yellow": color === "yellow",
            "form-button-black": color === "black",
            "form-button-grey": color === "grey",
            "form-button-disable": disabled,
          })}
          onClick={() => onClick()}
        >
          {label}
        </button>
      )}
    </Fragment>
  );
};

export default FormButton;
