 import { useEffect } from "react";
import userAuth from "../../hooks/userAuth";
import { useNavigate } from "react-router-dom";

const PrivateRoute = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = userAuth.getToken()
    if (token === null) navigate("/login")
  }, [])

  return props.children;
};

export default PrivateRoute;
