import "./userManagerEdit.scss";
import { useState, useEffect, Fragment } from "react";

import Modal from "../../../common/Modal/modal"
import Form from "../../../common/Form/form";
import Loader from "../../../common/Loader/loader";

import adminModel from "../../../models/adminModel";
import roleModel from "../../../models/roleModel";
import statusModel from "../../../models/statusModel";

import editUserManager from "./userManagerEdit.json";

const UserManagerEdit = ({ userId, editUser, setModalMessage }) => {
  const [user, setUser] = useState(null);                // Guarda el estado de un usuario
  const [editFields, setEditFields] = useState([]);      // Guarda los campos del formulario de edicion 

  const getUser = adminModel.useGet();                    // Hook que permite consultar el estado de un usuario 
  const getAllRole = roleModel.useGetAll();               // Hook que permite consultar la lista de roles
  const getAllStatus = statusModel.useGetAll();           // Hook que permite consultar la lista de estados
  const updateUser = adminModel.useUpdate();              // Hook que permite actualizar un usuario

  // Al cargar el componente se consulta el usuario, la lista de roles y la lista de estados
  useEffect(() => {
    getUser.fetch(userId)
    getAllRole.fetch()
    getAllStatus.fetch()
  }, []);

  // Recibo la respuesta de la consulta del usuario
  useEffect(() => {
    if (getUser.res.status === "load") {
      let data = getUser.res.data
      data = {...data, role: data.role.map(item => item.id)}
      data = {...data, statusId: data.statusId.id}
      setUser(data)
    };
  }, [getUser.res.status]);

  // Recibo la respuesta de la consulta de la lista de roles y estados
  useEffect(() => {
    if (getAllStatus.res.status === "load" && getAllRole.res.status === "load") {
      const newEditFieldStatus = editUserManager.map(item => item.id === "statusId"? {...item, options: getAllStatus.res.data}:item)
      const newEditFieldRole = newEditFieldStatus.map(item => item.id === "role"? {...item, options: getAllRole.res.data}:item)
      setEditFields(newEditFieldRole)
    }
  }, [getAllStatus.res.status, getAllRole.res.status]);

  // Recibo la respuesta de actualizacion de usuario
  useEffect(() => {
    if (updateUser.res.status === "load") {
      editUser();
      setModalMessage({type:"success", message: "Usuario actualizado exitosamente"});
    } else if (updateUser.res.status === "error"){
      editUser();
      setModalMessage({type:"error", message: "Error al actualizar el usuario"});
    }
  }, [updateUser.res.status]);

  // Funcion de actulizacion de usuario
  const submit = () => {
    delete user.password;
    user.statusId = parseInt(user.statusId)
    updateUser.fetch(userId, user)
  }

  return (
    <Modal width="700px" height="350px" setToggle={editUser}>
      <div className="edit-user-form-content">
        {getUser.res.status === "load" && getAllRole.res.status === "load" && getAllStatus.res.status === "load" && user !== null ? (
          <Form
            list={editFields}
            data={user}
            setData={setUser}
            submit={submit}
            cancel={editUser}
          />
        ) : (
          <div>
            <Loader size="big" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UserManagerEdit;
