import "./newClient.scss";
import { Fragment, useState, useEffect } from "react";

import Form from "../../../common/Form/form";
import clientModel from "../../../models/clientModel";

import newClientList from "./newClient.json";

const NewClient = ({ setMenu, clientId, userId, setModalMessage }) => {
  const [client, setClient] = useState({});
  const [clientList, setClientList] = useState([]);

  const getClient = clientModel.useGet();
  const updateClient = clientModel.useUpdate();
  const createClient = clientModel.useAdd();

  useEffect(() => {
    let defaultClient = {};
    newClientList
      .filter((item) => item.defaultValue !== undefined)
      .map((item) => {
        defaultClient = { ...defaultClient, [item.id]: item.defaultValue };
      });
    newClientList.map((item) => {
      if (item.id === "submit") item.label = "Nuevo";
    });
    setClientList(newClientList);
  }, []);

  useEffect(() => {
    if (clientId) {
      getClient.fetch(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (getClient.res.status === "load") {
      setClient(getClient.res.data);
      newClientList.map((item) => {
        if (item.id === "submit") item.label = "Editar";
      });
      setClientList(newClientList);
    }
    if (getClient.res.status === "error") {
      console.error(getClient.res.msg);
    }
  }, [getClient.res.status]);

  useEffect(() => {
    if (updateClient.res.status === "load") {
      setMenu("user");
      setModalMessage({
        type: "success",
        message: "Productor actualizado exitosamente",
      });
    }
    if (updateClient.res.status === "error") {
      setModalMessage({
        type: "error",
        message: `Error al actualizar el productor.\n${updateClient.res.msg}`,
      });
      console.error(updateClient.res.msg);
    }
  }, [updateClient.res.status]);

  useEffect(() => {
    if (createClient.res.status === "load") {
      setMenu("user");
      setModalMessage({
        type: "success",
        message: "Productor creado exitosamente",
      });
    }
    if (createClient.res.status === "error") {
      setModalMessage({
        type: "error",
        message: `Error al crear el productor.\n${createClient.res.msg}`,
      });
    }
  }, [createClient.res.status]);

  const submit = () => {
    if (clientId) updateClient.fetch(clientId, { ...client, userId: userId });
    else {
      createClient.fetch({ ...client, userId: userId });
    }
  };

  const cancel = () => {
    setMenu("user");
  };

  return (
    <Fragment>
      <div className="new-client-content">
        <h2>Nuevo Productor</h2>
        <Form
          list={clientList}
          data={client}
          setData={setClient}
          submit={submit}
          cancel={cancel}
        />
      </div>
    </Fragment>
  );
};

export default NewClient;
