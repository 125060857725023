import "./userManagerTable.scss";
import classnames from "classnames";
import { Fragment } from "react";

import { BsPencilFill, BsFiletypeKey, BsTrash } from "react-icons/bs";
import { RiKey2Line } from "react-icons/ri";

const UserManagerTable = ({
  columnsList,
  userList,
  setEditApiKeys,
  setEdit,
  setEditPassword,
  deleteUser,
}) => {
  return (
    <table className="user-manager-table">
      <thead>
        <tr>
          {columnsList.map((item) => (
            <th key={item.id} className="user-manager-head-cell">
              {item.label}
            </th>
          ))}
          <th className="user-manager-head-cell"></th>
        </tr>
      </thead>
      <tbody>
        {userList.map((item, index) => (
          <tr key={item.id}>
            {columnsList.map((col) => (
              <td
                key={`item${item.id}-col${col.id}`}
                className={classnames("user-manager-cell", {
                  "user-manager-filled": index % 2 === 0,
                })}
              >
                {item[col.id]}
              </td>
            ))}

            <td
              className={classnames("user-manager-cell", {
                "user-manager-filled": index % 2 === 0,
              })}
            >
              {item.id !== 1 ? (
                <Fragment>
                  <div
                    className="user-manager-keys"
                    onClick={() => setEditApiKeys(item.id)}
                  >
                    <BsFiletypeKey />
                  </div>
                  <div
                    className="user-manager-edit"
                    onClick={() => setEdit(item.id)}

                  >
                    <BsPencilFill />
                  </div>
                  <div
                    className="user-manager-pass"
                    onClick={() => setEditPassword(item.id)}
                  >
                    <RiKey2Line />
                  </div>
                  <div
                    className="user-manager-delete"
                    onClick={() => deleteUser(item.id)}
                  >
                    <BsTrash />
                  </div>
                </Fragment>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserManagerTable;
