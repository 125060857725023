import "./editUser.scss";
import { useState, useEffect, Fragment, useReducer } from "react";

import Form from "../../../common/Form/form";
import Loader from "../../../common/Loader/loader";
import Modal from "../../../common/Modal/modal"

import fieldList from "./configs/formConfig.json";
import userModel from "../../../models/userModel";

const EditUser = ({ userId, editUser, setModalMessage }) => {
  const [user, setUser] = useState(null);       // Guarda el estado de un usuario

  const getUser = userModel.useGet();           // Hook que permite consultar un usuario
  const updateUser = userModel.useUpdate();     // Hook que permite actualizar un usuario


  // Al cargar el componente se consulta el usuario
  useEffect(() => {
    getUser.fetch()
  }, []);

  // Recibo la respuesta de la consulta del usuario
  useEffect(() => {
    if (getUser.res.status === "load") setUser(getUser.res.data);
  }, [getUser.res.status]);

  // Recibo la respuesta de la actualizacion de un usuario
  useEffect(() => {
    if (updateUser.res.status === "load") {
      editUser();
      setModalMessage({type:"success", message: "Usuario actualizado exitosamente"});
    } else if (updateUser.res.status === "error"){
      editUser();
      setModalMessage({type:"error", message: "Error al actualizar el usuario"});
    }
  }, [updateUser.res.status]);

  // Funcion de actualizacion de un usuario
  const submit = () => {
    if(user.role) user.role = user.role.map(role => role.id)
    if(user.statusId) user.statusId = user.statusId.id
    updateUser.fetch(user)
  }

  return (
    <Fragment>
        <Modal width="675px" height="280px" setToggle={editUser}>
          <div className="edit-user-form-content">
            {getUser.res.status === "load" && user !== null ? (
              <Form
                list={fieldList}
                data={user}
                setData={setUser}
                submit={submit}
                cancel={editUser}
              /> ) : <Loader size="big" />}
          </div>
        </Modal>
    </Fragment>
  );
};

export default EditUser;
