import "./client.scss";
import { useState, useEffect, Fragment } from "react";

import Submenu from "../../../common/Submenu/submenu";
import Info from "../../../common/Info/info";
import TableList from "../../../common/TableList/tableList";
import Loader from "../../../common/Loader/loader";
import { BackButton, DownloadButton } from "../../../custom/Buttons/buttons";

import clientModel from "../../../models/clientModel";
import farmModel from "../../../models/farmModel";
import reportModel from "../../../models/reportModel";

import fileDownload from "js-file-download";

import tableCol from "./configs/table.json";
import infoList from "./configs/info.json";

import { Grid, GridRow } from "../../../common/Grid/grid";
import TableSearch from "../../../common/TableSearch/tableSearch";
import Farm from "../Farm/farm";

const Client = ({
  setMenu,
  setItemId,
  clientId,
  setPolygon,
  isDemo,
  setModalMessage,
  setModalDecision,
}) => {
  const [client, setClient] = useState({}); // Guarda el estado del productor
  const [farmList, setFarmList] = useState([]); // Guarda la list ade lotes de un productor
  const [search, setSearch] = useState(""); // Guarda el str de busqueda
  const [polygonList, setPolygonList] = useState([]); // Guarda la lista de polygonos de cada farm

  const [infoMenu, setInfoMenu] = useState(false);
  const [farmMenu, setFarmMenu] = useState(false);

  const getClient = clientModel.useGet(); // Hook que permite consultar un cliente
  const getFarm = farmModel.useGetAll(); // Hook que permite consultar los lotes
  const deleteFarm = farmModel.useRemove(); // Hook que permite eliminar un lote
  const getExcelReport = reportModel.useExcelReport(); // Hook para descargar el reporte
  const getPDFReport = reportModel.usePDFReport(); // Hook para descargar el reporte

  // Al recargar el componente consulta el cliente y la lista de lotes
  useEffect(() => {
    getClient.fetch(clientId);
    getFarm.fetch(clientId);
  }, []);

  // Recibo la respuesta de la consulta de productor
  useEffect(() => {
    if (getClient.res.status === "load") setClient(getClient.res.data);
  }, [getClient.res.status]);

  // Recibo la respuesta de la consulta de lista de lotes
  useEffect(() => {
    if (getFarm.res.status === "load") setFarmList(getFarm.res.data);
  }, [getFarm.res.status]);

  // Recibo la respuesta de la consulta de eliminacion de lote
  useEffect(() => {
    if (deleteFarm.res.status === "load") {
      getFarm.fetch(clientId);
      setModalMessage({
        type: "success",
        message: "Lote eliminado exitosamente",
      });
    }
  }, [deleteFarm.res.status]);

  // Se concatenan los polinomios de todos los lotes
  useEffect(() => {
    let newPolygonList = [];
    farmList.map((item) => {
      if (item.geoPolynomial)
        newPolygonList = newPolygonList.concat(JSON.parse(item.geoPolynomial));
    });
    setPolygon(newPolygonList);
  }, [farmList]);

  // Al actualizarse el farm actulizao el poligono
  useEffect(() => {
    const newPolygonList = farmList.map((farm) => {
      const polygon = JSON.parse(farm.geoPolynomial);
      const newPolygon = {
        ...polygon,
        properties: {
          ...polygon.properties,
          id: farm.id,
          name: farm.name,
          clientId: farm.clientId,
          province: farm.province,
          city: farm.city,
          department: farm.department,
          postalCode: farm.postalCode,
          createdAt: farm.createdAt,
          updatedAt: farm.updatedAt,
        },
      };
      return newPolygon;
    });
    setPolygonList(newPolygonList);
  }, [farmList]);

  // Funcion de seleccion de lote
  const getItem = (id) => {
    setPolygon([]);
    setItemId("farm", id);
    setMenu("farm");
  };

  // Funcion de edicion de lote
  const editItem = (id) => {
    setPolygon([]);
    setItemId("farm", id);
    setMenu("newFarm");
  };

  // Funcion de eliminacion de lote
  const deleteItem = (id) => {
    setModalDecision({
      type: "warning",
      message: "¿Está seguro que desea eliminar el lote?",
      accept: () => deleteFarm.fetch(id),
    });
  };

  // Funcion de creacion de nuevo lote
  const createItem = () => {
    setPolygon([]);
    setItemId("farm", null);
    setMenu("newFarm");
  };

  // Funcion de retorno al menu anterior
  const volver = () => {
    setPolygon([]);
    setMenu("user");
  };

  // Recibo la respuesta de descarga del excel
  useEffect(() => {
    if (getExcelReport.res.status === "load") {
      const curtm = Date.now();
      const now = new Date(curtm);
      const day = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();
      fileDownload(
        getExcelReport.res.data,
        `reporte-${year}${month}${day}.xlsx`
      );
    }
  }, [getExcelReport.res.status]);

  // Recibo la respuesta de descarga del PDF
  useEffect(() => {
    if (getPDFReport.res.status === "load") {
      const curtm = Date.now();
      const now = new Date(curtm);
      const day = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();
      fileDownload(
        getPDFReport.res.data,
        `certificado-${year}${month}${day}.pdf`
      );
    }
  }, [getPDFReport.res.status]);

  // Funcion para descarga del excel
  const downloadExcelFile = () => {
    getExcelReport.fetch({ geojson: polygonList }, { band: 1 });
  };

  // Funcion para descarga del excel
  const downloadPDFFile = () => {
    getPDFReport.fetch({ geojson: polygonList }, { band: 1 });
  };

  return (
    <div className="client-content">
      {getClient.res.status === "loading" ? (
        <div>
          <Loader size="big" />
        </div>
      ) : (
        <Fragment>
          {getClient.res.status === "load" ? (
            <Fragment>
              <div className="client-title">{client.name}</div>
              <Submenu
                hideMenu={infoMenu}
                setHideMenu={setInfoMenu}
                title={"Info"}
              >
                <Info infoList={infoList} data={client} />
              </Submenu>

              <Submenu
                hideMenu={farmMenu}
                setHideMenu={setFarmMenu}
                title={"Lotes"}
              >
                {getFarm.res.status === "loading" ? (
                  <Loader size="small" />
                ) : (
                  <Fragment>
                    <Grid>
                      <GridRow width={6}></GridRow>
                      <GridRow width={5}>
                        <TableSearch
                          id="search-farm"
                          label=""
                          size="small"
                          placeholder="Buscar"
                          labelPosition="side"
                          value={search}
                          onSearch={setSearch}
                        />
                      </GridRow>
                    </Grid>
                    <TableList
                      colList={tableCol}
                      rowList={farmList.filter((item) =>
                        item.name.includes(search)
                      )}
                      createItem={createItem}
                      disblaeCreate={isDemo && farmList.length >= 5}
                      getItem={getItem}
                      editItem={editItem}
                      deleteItem={deleteItem}
                    />
                  </Fragment>
                )}
              </Submenu>
            </Fragment>
          ) : (
            <h1>Error</h1>
          )}
        </Fragment>
      )}
      <BackButton goBack={volver} />
    </div>
  );
};

export default Client;
