import "./formChoise.scss";
import classnames from "classnames";
import React, { Fragment, useState, useEffect } from "react";

const FormChoise = ({ id, options, choise, onChange }) => {
  return (
    <div className="form-choise-control" >
      {options.map((option, index) => (
        <Fragment key={`${option.id}-fragment`}>
          <input
            key={`${option.id}-input`}
            type="radio"
            id={option.id}
            name="choice"
            value={option.value}
            className="form-choise-input"
            onChange={() => onChange(id, option.value)}
          />
          <label
            key={`${option.id}-label`}
            htmlFor={option.id}
            style={{ width: `calc((100% - 4px)/${options.length})` }}
            className={classnames("form-choise-label-middle", {
              "form-choise-label-left": index === 0,
              "form-choise-label-right": index + 1 === options.length,
              "form-choise-selected": choise === option.value,
            })}
          >
            {option.label}
          </label>
        </Fragment>
      ))}
    </div>
  );
};

export default FormChoise;
